import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import MiniSearchBar from "components/layouts/MiniSearchBar";
import EmptyTable from "components/utils/table-layouts/EmptyTable";
import { getAllClientsCommission,getAllClientsCommissionS } from "services/properties";
import { IconExport, IconFilter } from "../../assets/icons";
import OutflowHeader from "components/layouts/OutflowHeader";
import OutflowTable from "components/layouts/OutflowTable";
import Loader from "components/Utilities/Loader";
import { CSVLink } from "react-csv";
import { outFlowHeaders } from "components/Utilities/csvHeader";
import prettyMoney from "pretty-money";
import Salaries from "components/pages/Membership";

const Outflow = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [addPay, setAddPay] = useState(false);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("add");
  const [state2, setState2] = useState({
    adminId: "",
    clientFullName: "",
    clientId: "",
    commission: "",
    createdAt: "",
    deletedAt: "",
    id: "",
    propertyName: "",
    referrerId: "",
    status: "",
    updatedAt: "",
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const value = await getAllClientsCommission();
      setState(value?.commissions?.commissions);
      setLoading(false);
    })();
  }, []);
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    (async () => {
      setLoading(true);
      const value = await getAllClientsCommissionS(e.target.value);
      setState(value.commissions.commissions);
      setLoading(false);
    })();
  };

  const csvReport = {
    fileName: "whiterose.csv",
    headers: outFlowHeaders,
    data: state,
  };

  const addAmountF = (arr) => {
    let amountPaid = 0;
    arr.map((x) => {
      return (amountPaid = amountPaid + x.commission);
    });
    return amountPaid;
  };

  let totalCommission = addAmountF(state);
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  return (
    <ReferralsStyles>
      {document.location.pathname === "/outflow" ? (
        <OutflowHeader
          title="Outflow"
          type={type}
          setType={setType}
          setState={setState2}
          addPay={addPay}
          setAddPay={setAddPay}
          set={setState}
          state={state2}
          subTitle={prettyMoney(prettyDollarConfig, totalCommission)}
        />
      ) : (
        <Salaries
          title="Salaries"
          type={type}
          setType={setType}
          setState={setState2}
          addPay={addPay}
          setAddPay={setAddPay}
          set={setState}
          state={state2}
          subTitle={prettyMoney(prettyDollarConfig, totalCommission)}
        />
      )}
      <hr />
      <div className="search-filter-export">
        <div className="inputSearch">
          <MiniSearchBar
            name={searchQuery}
            color="#fff"
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search client, name or property..."
          />
        </div>
        <div className="filter-export">
          <div className="filter">
            <IconFilter /> <h3>Filters</h3>
          </div>
          <div className="export">
            <IconExport />
            <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                color: "gray",
              }}
            >
              <h3 style={{ color: "black" }}>Export</h3>
            </CSVLink>
          </div>
        </div>
      </div>
      <hr />

      {document.location.pathname === "/outflow" ? (
        <div className="table">
          {loading ? (
            <Loader />
          ) : (
            <Grid container>
              {state && state.length > 0 ? (
                <OutflowTable
                  type={type}
                  setType={setType}
                  state={state}
                  state2={state2}
                  setState2={setState2}
                  setAddPay={setAddPay}
                  addPay={addPay}
                  setState={setState}
                />
              ) : (
                <EmptyTable />
              )}
            </Grid>
          )}
        </div>
      ) : (
        <Salaries
          type={type}
          setType={setType}
          state={state}
          state2={state2}
          setState2={setState2}
          setAddPay={setAddPay}
          addPay={addPay}
          setState={setState}
        />
      )}
    </ReferralsStyles>
  );
};

export default React.memo(Outflow);

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  .table {
    margin: 20px 40px;

    @media (max-width: 640px) {
      margin: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 125vh;
    }
  }

  .search-filter-export {
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
