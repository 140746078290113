import React from 'react'
import styled from '@emotion/styled'

const CheckboxStyles = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;

    label {
        font-size: 13px;
    }

    input {
        margin-right: 5px;
        background-color: transparent;
        border: 1px solid #A2A2A2;
        cursor: pointer;
        height: 15px;
        width: 15px;
        /* opacity: 0; */
        /* position: absolute; */

        &:unchecked {
            background-color: #ccc;
        }
        &:checked {
            background-color: var(--button-bg);
        }
    }
`

function Checkbox({label, value, onChange}) {
  return <CheckboxStyles>
    <input 
        type="checkbox" 
        checked={value} 
        onChange={onChange} 
        name="persist"
    />
      <label>
        {label}
    </label>
  </CheckboxStyles>
}

export default Checkbox 