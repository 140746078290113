import { useState } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { SalariesHeadCells } from "../../helpers/datasets/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { SalariesRows } from "helpers/datasets/table-rows";
import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const SalariesTable = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  return (
    <EnhancedTable
      headCells={SalariesHeadCells}
      rows={SalariesRows}
      page={page}
      paginationLabel="Showing"
      hasCheckbox={false}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      {SalariesRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

          return (
            <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell className={classes.tableCell}><input type="checkbox" /></TableCell>

              <TableCell className={classes.tableCell}>{row.date}</TableCell>
              <TableCell className={classes.tableCell}>{row.member}</TableCell>
              <TableCell className={classes.tableCell}>{row.memberId}</TableCell>
              <TableCell className={classes.tableCell}>{row.bank}</TableCell>
              <TableCell className={classes.tableCell}>{row.accountName}</TableCell>
              <TableCell className={classes.tableCell}>{row.accountNumber}</TableCell>
              <TableCell className={classes.tableCell}><NairaSign>N</NairaSign> {row.amountPaid}</TableCell>
            </TableRow>
          );
        })}
    </EnhancedTable>
  );
};

export default SalariesTable;


const NairaSign = styled.span`
  text-decoration: line-through;
  text-decoration-style: double;
`