import { TiDocumentText, TiMessages } from "react-icons/ti";
import { BsPeople } from "react-icons/bs";
import { MdOutlineGroups, MdOutlinePayments } from "react-icons/md";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { BiBarChartSquare, BiLayout } from "react-icons/bi";

export const menus = [
  { id: 0, title: "Dashboard", icon: BiLayout, path: "dashboard" },
  {
    id: 1,
    title: "Transactions",
    icon: TiDocumentText,
    path: "inflow",
    dropDowns: [
      { subId: 0, title: "Inflow", path: "inflow" },
      { subId: 1, title: "Outflow", path: "outflow" },
    ],
  },
  { id: 2, title: "Clients", icon: BsPeople, path: "clients" },
  { id: 3, title: "Payout", icon: MdOutlinePayments, path: "payout" },
  {
    id: 4,
    title: "Properties",
    icon: HiOfficeBuilding,
    path: "properties",
  },
  { id: 5, title: "Members", icon: MdOutlineGroups, path: "members" },
  { id: 6, title: "Messages", icon: TiMessages, path: "messages" },
  {
    id: 7,
    title: "Ranking",
    icon: BiBarChartSquare,
    path: "referral",
    dropDowns: [
      { subId: 0, title: "Referral Ranking", path: "referral-ranking" },
      { subId: 1, title: "Sales Ranking", path: "sales-ranking" },
      { subId: 2, title: "Client Ranking", path: "client-ranking" },
    ],
  },
  { id: 8, title: "Settings", icon: IoSettingsOutline, path: "settings" },
];
