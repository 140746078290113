export const commisssionsHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date",
  },
  {
    id: 2,
    label: "Client",
  },
  {
    id: 3,
    label: "Client ID",
  },
  {
    id: 4,
    label: "Property",
  },
  {
    id: 5,
    label: "Commission",
  },
  {
    id: 6,
    label: "Status",
  },
  {
    id: 7,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const InflowHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Client name",
  },
  {
    id: 2,
    label: "Phone number",
  },
  {
    id: 3,
    label: "Property",
  },

  {
    id: 4,
    label: "Client ID",
  },
  {
    id: 4,
    label: "Referrer ID",
  },
  {
    id: 5,
    label: "Total cost",
  },
  {
    id: 6,
    label: "Amount paid",
  },
  {
    id: 7,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const MembershipHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },

  {
    id: 1,
    label: "Date of Reg",
  },
  {
    id: 2,
    label: "Member name",
  },
  {
    id: 3,
    label: "Email",
  },
  {
    id: 5,
    label: "Member ID",
  },
  {
    id: 6,
    label: "Referrer ID",
  },
  {
    id: 7,
    label: "Amount paid",
  },
];

// <<<----- REFERRALS ------->>>
export const OutflowHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date",
  },
  {
    id: 2,
    label: "Client",
  },
  {
    id: 3,
    label: "Client ID",
  },
  {
    id: 4,
    label: "Referrer",
  },
  {
    id: 5,
    label: "Referrer ID",
  },
  {
    id: 6,
    label: "Property",
  },
  {
    id: 7,
    label: "Due Commission",
  },
];

// <<<----- REFERRALS ------->>>
export const SalariesHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date",
  },
  {
    id: 2,
    label: "Member",
  },
  {
    id: 3,
    label: "Member ID",
  },
  {
    id: 4,
    label: "Bank",
  },
  {
    id: 5,
    label: "Account name",
  },
  {
    id: 6,
    label: "Account number",
  },
  {
    id: 7,
    label: "Amount paid",
  },
  {
    id: 8,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const ClientsHeadCells = [
  {
    id: 1,
    label: <input type="checkbox" />,
  },
  {
    id: 2,
    label: "Date of Reg",
  },
  {
    id: 3,
    label: "Name",
  },
  {
    id: 4,
    label: "Phone number",
  },
  {
    id: 5,
    label: "Email address",
  },
  {
    id: 6,
    label: "Client ID",
  },
  {
    id: 7,
    label: "Status",
  },
  {
    id: 8,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const PayoutHeadCells = [
  {
    id: 1,
    label: <input type="checkbox" />,
  },
  {
    id: 2,
    label: "Date",
  },
  {
    id: 3,
    label: "Client",
  },
  {
    id: 4,
    label: "Client ID",
  },
  {
    id: 5,
    label: "Referrer",
  },
  {
    id: 6,
    label: "Referrer ID",
  },
  {
    id: 7,
    label: "Property",
  },
  {
    id: 8,
    label: "Commission",
  },
  {
    id: 9,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const DiscountedSalariesHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: <input type="checkbox" />,
  },
  {
    id: 2,
    label: "Date",
  },
  {
    id: 3,
    label: "Member",
  },
  {
    id: 4,
    label: "Member ID",
  },
  {
    id: 5,
    label: "Bank",
  },
  {
    id: 6,
    label: "Account name",
  },
  {
    id: 7,
    label: "Account number",
  },
  {
    id: 8,
    label: "Amount",
  },
  {
    id: 9,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const MembersHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },

  {
    id: 1,
    label: "Date of Reg",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Phone",
  },
  {
    id: 3,
    label: "Email address",
  },
  {
    id: 3,
    label: "Referrer ID",
  },
  {
    id: 4,
    label: "Level",
  },
  {
    id: 5,
    label: "Total Referrals",
  },
  {
    id: 6,
    label: "Referred by",
  },

  {
    id: 7,
    label: "status",
  },
  {
    id: 8,
    label: "action",
  },
];
// <<<----- REFERRALS ------->>>
export const ReferrerRankingHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "S/N",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Phone number",
  },
  {
    id: 4,
    label: "Referral ID",
  },
  {
    id: 5,
    label: "Downlines",
  },
  {
    id: 6,
    label: "Action",
  },
];

// <<<----- REFERRALS ------->>>
export const SaleRankingHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },

  {
    id: 2,
    label: "Date",
  },
  {
    id: 3,
    label: "Name",
  },
  {
    id: 4,
    label: "Phone number",
  },
  {
    id: 5,
    label: "Client ID",
  },
  {
    id: 6,
    label: "Units sold",
  },
  {
    id: 7,
    label: "Amount sold",
  },
];

// <<<----- REFERRALS ------->>>
export const ClientRankingHeadCells = [
  {
    id: 1,
    label: <input type="checkbox" />,
  },
  {
    id: 2,
    label: "Date",
  },

  {
    id: 4,
    label: "Name",
  },
  {
    id: 5,
    label: "Phone number",
  },
  {
    id: 6,
    label: "Client ID",
  },
  {
    id: 7,
    label: "Units Bought",
  },
  {
    id: 8,
    label: "Total Amount",
  },
];

// <<<----- Downlines First Gen ------->>>
export const downlinesHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date of Reg",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Phone Number",
  },
  {
    id: 4,
    label: "Email Address",
  },
  {
    id: 5,
    label: "Level",
  },
  {
    id: 6,
    label: "Member ID",
  },
  {
    id: 7,
    label: "Action",
  },
];
