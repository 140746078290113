import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
import Loader from "components/Utilities/Loader";
import InflowHeader from "../layouts/InflowHeader";
import MiniSearchBar from "components/layouts/MiniSearchBar";
import InflowTable from "components/layouts/InflowTable";
import EmptyTable from "components/utils/table-layouts/EmptyTable";
import { IconExport, IconFilter } from "../../assets/icons";
import {
  getAllSalesProperties,
  getAllSalesPropertiesS,
} from "services/properties";
import { headers } from "components/Utilities/csvHeader";
import prettyMoney from "pretty-money";
import Inflows from "components/pages/Membership";

const Inflow = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [newTrnx, setNewTrnx] = useState(false);
  const [state, setState] = useState([]);
  const [stateS, setStateS] = useState([]);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("add");

  const csvReport = {
    fileName: "whiterose.csv",
    headers,
    data: state,
  };

  const [state2, setState2] = useState({
    client_id: "",
    full_name: "",
    payment_for: "",
    no_of_unit: "",
    payment_plan: "",
    cost_per_unit: "",
    mobile: "",
    total_amount: "",
    amount_just_paid: "",
    date_of_payment: "",
    pass_word: "",
  });

  console.log(state, searchQuery, "see info");
  useEffect(() => {
    (async () => {
      setLoading(true);
      const value = await getAllSalesProperties();
      setState(value.transactions.payments);
      setLoading(false);
    })();
  }, []);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    (async () => {
      setLoading(true);
      const value = await getAllSalesPropertiesS(e.target.value);
      setState(value.transactions.payments);
      setLoading(false);
    })();
  };
  const addAmountF = (arr) => {
    let amountPaid = 0;
    arr.map((x) => (amountPaid = amountPaid + x.ammountJustPaid));
    return amountPaid;
  };

  let totalSales = addAmountF(state);
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  console.log(searchQuery, "workingSear");

  return (
    <ReferralsStyles>
      {document.location.pathname === "/inflow" ? (
        <InflowHeader
          title="Inflow"
          state2={state2}
          setState2={setState2}
          subTitle={prettyMoney(prettyDollarConfig, totalSales)}
          newTrnx={newTrnx}
          setNewTrnx={setNewTrnx}
          setState={setState}
          setType={setType}
          type={type}
        />
      ) : (
        <Inflows
          state2={state2}
          setState2={setState2}
          subTitle={prettyMoney(prettyDollarConfig, totalSales)}
          newTrnx={newTrnx}
          setNewTrnx={setNewTrnx}
          setState={setState}
          setType={setType}
          type={type}
        />
      )}
      <hr />
      <div className="search-filter-export">
        <div className="inputSearch">
          <MiniSearchBar
            name={searchQuery}
            color="#fff"
            onChange={handleChange}
            placeHolder="Search client, name or property..."
            className="longSearch"
          />
        </div>
        <div className="filter-export">
          <div className="filter">
            <IconFilter /> <h3>Filters</h3>
          </div>
          <div className="export">
            <IconExport />
            <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                color: "gray",
              }}
            >
              <h3 style={{ color: "black" }}>Export</h3>
            </CSVLink>
          </div>
        </div>
      </div>
      <hr />
      {document.location.pathname === "/inflow" ? (
        <div className="table">
          {loading ? (
            <Loader />
          ) : (
            <Grid container>
              {state && state.length > 0 ? (
                <InflowTable
                  state={state}
                  setState={setState}
                  newTrnx={newTrnx}
                  setNewTrnx={setNewTrnx}
                  state2={state2}
                  setState2={setState2}
                  setType={setType}
                  type={type}
                />
              ) : (
                <EmptyTable />
              )}
            </Grid>
          )}
        </div>
      ) : (
        <Inflows
          state2={state2}
          setState2={setState2}
          subTitle={prettyMoney(prettyDollarConfig, totalSales)}
          newTrnx={newTrnx}
          setNewTrnx={setNewTrnx}
          setState={setState}
          setType={setType}
          type={type}
        />
      )}
    </ReferralsStyles>
  );
};

export default React.memo(Inflow);

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  .table {
    margin: 20px 40px;

    @media (max-width: 640px) {
      margin: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 125vh;
    }
  }

  .search-filter-export {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
