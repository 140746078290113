export const IconSales = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 23.0625C4.5 23.0625 2.25 21.375 2.25 17.4375V9.5625C2.25 5.625 4.5 3.9375 7.875 3.9375H19.125C22.5 3.9375 24.75 5.625 24.75 9.5625V17.4375C24.75 21.375 22.5 23.0625 19.125 23.0625H7.875Z"
        stroke="#128EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 16.875C11.636 16.875 10.125 15.364 10.125 13.5C10.125 11.636 11.636 10.125 13.5 10.125C15.364 10.125 16.875 11.636 16.875 13.5C16.875 15.364 15.364 16.875 13.5 16.875Z"
        stroke="#128EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1875 10.6875V16.3125"
        stroke="#128EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8125 10.6875V16.3125"
        stroke="#128EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconCommission = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83999 22.96C4.47999 22.96 2.23999 21.28 2.23999 17.36V9.51998C2.23999 5.59998 4.47999 3.91998 7.83999 3.91998H19.04C22.4 3.91998 24.64 5.59998 24.64 9.51998V17.36C24.64 21.28 22.4 22.96 19.04 22.96H7.83999Z"
        stroke="#28C52B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9601 19.04H5.6001"
        stroke="#28C52B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4401 16.8C11.5844 16.8 10.0801 15.2957 10.0801 13.44C10.0801 11.5843 11.5844 10.08 13.4401 10.08C15.2958 10.08 16.8001 11.5843 16.8001 13.44C16.8001 15.2957 15.2958 16.8 13.4401 16.8Z"
        stroke="#28C52B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2799 7.84003H17.9199"
        stroke="#28C52B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconMembership = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17285 23.4528C10.4721 24.1136 11.9505 24.4832 13.5073 24.4832C15.0081 24.4832 16.4193 24.1472 17.6849 23.5312"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4703 22.3104C19.7507 22.3104 18.3567 20.9164 18.3567 19.1968C18.3567 17.4772 19.7507 16.0832 21.4703 16.0832C23.1899 16.0832 24.5839 17.4772 24.5839 19.1968C24.5839 20.9164 23.1899 22.3104 21.4703 22.3104Z"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4095 22.3104C3.6899 22.3104 2.2959 20.9164 2.2959 19.1968C2.2959 17.4772 3.6899 16.0832 5.4095 16.0832C7.12909 16.0832 8.5231 17.4772 8.5231 19.1968C8.5231 20.9164 7.12909 22.3104 5.4095 22.3104Z"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.90869 13.8544C4.19989 11.0096 5.72309 8.53437 7.94069 6.96637"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9951 6.91046C21.2351 8.46726 22.7807 10.9425 23.0943 13.7985"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5072 8.62405C11.7876 8.62405 10.3936 7.23004 10.3936 5.51045C10.3936 3.79086 11.7876 2.39685 13.5072 2.39685C15.2267 2.39685 16.6208 3.79086 16.6208 5.51045C16.6208 7.23004 15.2267 8.62405 13.5072 8.62405Z"
        stroke="#F8920F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconDownlines = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.947 8.01919C18.4014 7.96319 17.1694 6.69759 17.1694 5.12959C17.1694 3.52799 18.4574 2.23999 20.059 2.23999C21.6606 2.23999 22.9486 3.53919 22.9486 5.12959C22.9374 6.69759 21.7054 7.96319 20.1598 8.01919C20.0926 8.00799 20.0142 8.00799 19.947 8.01919Z"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0063 16.1729C20.5407 16.4305 22.2319 16.1617 23.4191 15.3665C24.9983 14.3137 24.9983 12.5889 23.4191 11.5361C22.2207 10.7409 20.5071 10.4721 18.9727 10.7409"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89906 8.01919C8.44466 7.96319 9.67666 6.69759 9.67666 5.12959C9.67666 3.52799 8.38866 2.23999 6.78706 2.23999C5.18546 2.23999 3.89746 3.53919 3.89746 5.12959C3.90866 6.69759 5.14066 7.96319 6.68626 8.01919C6.75346 8.00799 6.83186 8.00799 6.89906 8.01919Z"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83987 16.1729C6.30547 16.4305 4.61428 16.1617 3.42708 15.3665C1.84788 14.3137 1.84788 12.5889 3.42708 11.5361C4.62548 10.7409 6.33907 10.4721 7.87347 10.7409"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2271 16.3856C11.6815 16.3296 10.4495 15.064 10.4495 13.496C10.4495 11.8944 11.7375 10.6064 13.3391 10.6064C14.9407 10.6064 16.2287 11.9056 16.2287 13.496C16.2175 15.064 14.9855 16.3408 13.4399 16.3856C13.3727 16.3744 13.2943 16.3744 13.2271 16.3856Z"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1807 23.7441C11.9727 24.9425 14.9071 24.9425 16.6991 23.7441C18.2783 22.6913 18.2783 20.9665 16.6991 19.9137C14.9183 18.7265 11.9727 18.7265 10.1807 19.9137C8.60154 20.9665 8.60154 22.6913 10.1807 23.7441Z"
        stroke="#8469FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconAddCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 9.99996C18.3337 5.41663 14.5837 1.66663 10.0003 1.66663C5.41699 1.66663 1.66699 5.41663 1.66699 9.99996C1.66699 14.5833 5.41699 18.3333 10.0003 18.3333C14.5837 18.3333 18.3337 14.5833 18.3337 9.99996Z"
        fill="#fff"
        fillOpacity="0.24"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 10H13.3337"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.3333V6.66663"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconFilter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4997 1.75C16.4163 1.75 17.1663 2.5 17.1663 3.41667V5.25C17.1663 5.91667 16.7497 6.75 16.333 7.16667L12.7497 10.3333C12.2497 10.75 11.9163 11.5833 11.9163 12.25V15.8333C11.9163 16.3333 11.583 17 11.1663 17.25L9.99967 18C8.91634 18.6667 7.41634 17.9167 7.41634 16.5833V12.1667C7.41634 11.5833 7.08301 10.8333 6.74967 10.4167L3.58301 7.08333C3.16634 6.66667 2.83301 5.91667 2.83301 5.41667V3.5C2.83301 2.5 3.58301 1.75 4.49967 1.75H15.4997Z"
        stroke="#7D7D7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.10833 1.75L5 8.33333"
        stroke="#7D7D7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconExport = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7666 9.73328L9.89994 11.8666L12.0333 9.73328"
        stroke="#7D7D7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8999 3.33331V11.8083"
        stroke="#7D7D7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 10.15C16.6668 13.8333 14.1668 16.8166 10.0002 16.8166C5.8335 16.8166 3.3335 13.8333 3.3335 10.15"
        stroke="#7D7D7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const IconExport1 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7666 9.73328L9.89994 11.8666L12.0333 9.73328"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8999 3.33331V11.8083"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 10.15C16.6668 13.8333 14.1668 16.8166 10.0002 16.8166C5.8335 16.8166 3.3335 13.8333 3.3335 10.15"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconAngleRight = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.33337L6.04044 7.29293C5.64991 7.68346 5.64992 8.31662 6.04044 8.70715L10 12.6667"
        stroke="#000"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export function IconEdit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 5.63l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75l1.83-1.83a.996.996 0 0 0 0-1.41z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function IconTrash(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          d="M4 6h16l-1.58 14.22A2 2 0 0 1 16.432 22H7.568a2 2 0 0 1-1.988-1.78L4 6z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M7.345 3.147A2 2 0 0 1 9.154 2h5.692a2 2 0 0 1 1.81 1.147L18 6H6l1.345-2.853z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M2 6h20"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10 11v5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M14 11v5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
}

export function IconCaution(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
      <path
        d="M14.9 13.4L8.5.6C8.1-.2 7-.2 6.6.6L.1 13.4c-.4.7.2 1.6 1 1.6h12.8c.8 0 1.3-.9 1-1.6zM7.5 13c-.8 0-1.5-.7-1.5-1.5S6.6 10 7.5 10s1.5.7 1.5 1.5c0 .9-.7 1.5-1.5 1.5zm1-4h-2L6 5.5c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5L8.5 9z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
