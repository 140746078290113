import React, { useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { IconAddCircle } from "../../assets/icons";
import { Drawer, Button, CircularProgress } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import {
  createClient,
  getAllClients,
  patchAClient,
  getProperties,
  getUserByReferralCode,
} from "services/properties";
import { makeStyles } from "@mui/styles";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "100%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,
      padding: "10px 0px",

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },

      "&:disabled": {
        background: "#ffa8b6",
        cursor: "not-allowed !important",
      },
    },
  },
  response: {
    textAlign: "center",
    marginTop: "14px",
    marginBottom: "14px",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));
function ClientsHeader({
  title,
  subTitle,
  setRegClient,
  editClient,
  regClient,
  setState: setNewState,
  edit,
  id,
  state2,
  setState2,
}) {
  const classes = useStyles();
  const [editState, setEditState] = useState();
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isNRC, setIsNRC] = useState(true);
  const [isRC, setIsRC] = useState(false);
  const [clientData, setClientData] = useState(false);

  const [AP, setAPF] = useState(0);

  const [state, setState] = useState({
    email: "",
    amount: "",
    propertyName: "",
    firstName: "",
    client: "",
    lastName: "",
    phone: "",
    date: "",
    gender: "",
    country: "",
    states: "",
    city: "",
    referrerFullname: "",
    residency: "",
    referralId: "",
    unit: "",
    password: "",
    status: "",
    client_id: "",
  });
  const addUser = () => {
    setState({
      email: "",
      amount: "",
      firstName: "",
      client: "",
      lastName: "",
      propertyName: "",
      phone: "",
      date: "",
      gender: "",
      country: "",
      states: "",
      city: "",
      referrerFullname: "",
      residency: "",
      referralId: "",
      unit: "",
      password: "",
      status: "",
      client_id: "",
    });
    setRegClient(true);
  };
  useEffect(() => {
    setEditState(editClient);
    if (editState) {
      const {
        email,
        address,
        numberOfunits,
        city,
        state,
        lastName,
        firstName,
        client,
        mobile,
        propertyPrice,
        propertyName,
        dateOfBirth,
        gender,
        referrerId,
        referrerFullname,
        status,
        client_id,
      } = editState;

      setState({
        propertyName: propertyName,
        email,
        amount: propertyPrice,
        firstName,
        client,
        lastName,
        phone: mobile,
        date: dateOfBirth,
        gender,
        country,
        states: state,
        city,
        referrerFullname,
        residency: address,
        referralId: referrerId,
        unit: numberOfunits,
        password: "",
        status,
        client_id,
      });
    }
    //eslint-disable-next-line
  }, [editClient, editState]);
  const {
    lastName,
    email,
    propertyName,
    firstName,
    client,
    phone,
    date,
    gender,
    country,
    states,
    city,
    residency,
    referrerFullname,
    referralId,
    unit,
    password,
    amount,
    status,
    client_id,
  } = state;

  console.log(propertyName);
  const fetchUserProfile = async (e) => {
    e.preventDefault();

    const { firstName, lastName } = await getUserByReferralCode(
      "users",
      referralId
    );

    setState({
      ...state,
      referrerFullname: `${firstName} ${lastName} `,
    });
  };
  // const handleChangeR = (e) => {
  //   const { name, value } = e.target;
  //   setState({
  //     ...state,
  //     [name]: value,
  //   });
  // };

  console.log(AP, "aaap");

  const handleSubmit = async (e) => {
    setLoading(true);
    const {
      email,
      amount,
      firstName,
      client,
      lastName,
      phone,
      date,
      gender,
      clientId,
      // country,
      states,
      city,
      residency,
      // referralId,
      unit,
      adminId,
      // password,
      referrerFullname,
      propertyName,
      status,
    } = state;

    e.preventDefault();
    const finalValue = {
      adminId,
      email,
      address: residency,
      clientId,
      numberOfunits: unit,
      city,
      state: states,
      lastName,
      firstName,
      client,
      mobile: phone,
      propertyPrice: amount,
      propertyName: propertyName,
      dateOfBirth: date,
      gender,
      referrerId: referralId,
      referrerFullname,
      status,
    };
    try {
      if (edit) {
        await patchAClient(id, finalValue);
        setLoading(false);
      } else {
        await createClient(finalValue);
        setLoading(false);
      }
      const value = await getAllClients();
      setState(value.clients.clients);
      setRegClient(false);
      window.location.reload();
      } catch (error) {
      console.error(error);
    }
  };
  // console.log(AP, "lll");
  useEffect(() => {
    (async () => {
      const { properties } = await getProperties();
      setProperties(properties.properties);
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (name == "propertyName") {
      properties.map((i) => {
        if (i.name == value) {
          console.log(i, "iiiam");
          setAPF(i.price);
        }
      });
    }
  };
  const handleChange2 = (e) => {
    const { name, value } = e.target;

    console.log(name, value, "nammmme");
  };

  const findClient = async () => {
    const { firstName, lastName, email, mobile } = await getUserByReferralCode(
      "clients",
      client_id
    );
    let clientData = {
      firstName,
      lastName,
      email,
      phone: mobile,
    };
    setClientData(clientData);
  };
  return (
    <>
      <OutflowHeaderStyles>
        <div className="meta">
          <HeaderTab>
            <div style={{ display: "flex" }}>
              <h3>{title}</h3>
            </div>

            <div className="PopButtons">
              <NewReferralButtonStyles onClick={addUser}>
                New Client <IconAddCircle />
              </NewReferralButtonStyles>
            </div>
          </HeaderTab>
          <p style={{ marginTop: "10px", fontSize: "15px", color: "#7D7D7E" }}>
            {subTitle}
          </p>
        </div>
      </OutflowHeaderStyles>

      {/* ============================================================ */}
      {/* Register new client */}
      {/* ============================================================ */}
      <Drawer
        anchor="right"
        open={regClient}
        sx={{ width: "450px" }}
        onClose={() => setRegClient(false)}
      >
        <DrawerHeader>
          <div>
            <h1 style={{ marginBottom: "5px" }}>Register new clients</h1>
            <p className="subText">
              Onboard new clients to purchase a property
            </p>
          </div>
          <div className="exit" onClick={() => setRegClient(false)}>
            <Cancel
              style={{
                fontSize: "30px",
              }}
            />
          </div>
        </DrawerHeader>
        <form>
          <DrawerBody>
            <div className="input-group">
              <div className="input-group">
                <div className="row4">
                  <div className="col">
                    <label>Returning Client?</label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "5%",
                      }}
                    >
                      <div
                        className="radioI"
                        onClick={() => {
                          setIsRC(true);
                          setIsNRC(false);
                        }}
                      >
                        Yes
                        <input
                          type="radio"
                          value={client}
                          placeholder="Enter state"
                          name="client"
                          checked={isRC}
                        />
                        <span class="checkmark"></span>
                      </div>

                      <div
                        className="radioI"
                        onClick={() => {
                          setIsRC(false);
                          setIsNRC(true);
                        }}
                      >
                        {" "}
                        No
                        <input
                          type="radio"
                          value={client}
                          name="client"
                          checked={isNRC}
                        />
                        <span class="checkmark"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isRC ? (
                <div className="row1" style={{ marginBottom: " 5% " }}>
                  <input
                    type="text"
                    placeholder="Enter client ID"
                    name="client_id"
                    value={client_id}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <button onClick={findClient} type="button">
                    Check
                  </button>
                </div>
              ) : (
                ""
              )}

              <div className="row4">
                <div className="col">
                  <label>First name</label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    name="firstName"
                    value={clientData ? clientData.firstName : firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col">
                  <label>Last name</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    placeholder="Enter last name"
                    name="lastName"
                    value={clientData ? clientData.lastName : lastName}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>Email address</label>
              <div className="row1">
                <input
                  type="email"
                  name="email"
                  value={clientData ? clientData.email : email}
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder="Enter email address"
                />
              </div>
            </div>

            <div className="input-group">
              <label>Phone number</label>
              <div className="row1">
                <input
                  type="text"
                  placeholder="Phone number"
                  name="phone"
                  value={clientData ? clientData.phone : phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="input-group">
              <div className="row4">
                <div className="col">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    name="date"
                    value={date}
                    onChange={handleChange}
                  />
                </div>
                <div className="col">
                  <label>Gender</label>
                  <select
                    name="gender"
                    value={gender}
                    onChange={handleChange}
                    style={{ width: "95%" }}
                  >
                    <option value="Select gender">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-group">
              <div className="row4">
                <div className="col">
                  <label>State</label>
                  <input
                    type="text"
                    value={states}
                    placeholder="Enter state"
                    onChange={handleChange}
                    name="states"
                  />
                </div>
                <div className="col">
                  <label>City</label>
                  <input
                    type="text"
                    value={city}
                    placeholder="Enter city"
                    onChange={handleChange}
                    name="city"
                  />
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>Status</label>
              <div className="row1">
                <select value={status} name="status" onChange={handleChange}>
                  <option value="">Select Status</option>
                  <option value="Pending">Pending</option>
                  <option value="In Progress">In progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>

            <div className="input-group">
              <label>Address</label>
              <div className="row1">
                <textarea
                  rows="3"
                  name="residency"
                  value={residency}
                  onChange={handleChange}
                  placeholder="Enter address of residency"
                ></textarea>
              </div>
            </div>
          </DrawerBody>
          <DrawerBody>
            <div className="input-group">
              <label>Referrer ID</label>
              <div className="row1">
                <input
                  type="text"
                  value={referralId}
                  name="referralId"
                  onChange={handleChange}
                />
                <button onClick={fetchUserProfile}>Check</button>
              </div>
            </div>

            <div className="input-group">
              <label>Full name</label>
              <div className="row1">
                <input
                  type="text"
                  disabled
                  onChange={handleChange}
                  value={referrerFullname}
                  name="referrerFullname"
                />
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <div className="input-group">
              <label>Properties of interest</label>
              <div className="row1">
                <select
                  value={propertyName}
                  name="propertyName"
                  onChange={handleChange}
                >
                  <option value="">Select Property</option>
                  {properties?.map((property) => {
                    return (
                      <option value={property.name}>{property.name}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="input-group">
              <div className="row4">
                <div className="col">
                  <label>Property price</label>
                  {/* <input
                    type="text"
                    value={amount}
                    name="amount"
                    placeholder="Enter amount"
                    onChange={handleChange}
                  /> */}
                  <NumberFormat
                    disabled
                    name={"amount"}
                    value={AP}
                    defaultValue={AP}
                    displayType={"input"}
                    placeholder={AP}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalSeparator="."
                    allowNegative={true}
                    // renderText={(value, props) => <div {...props}>{value}</div>}
                    // onValueChange={(e) =>
                    //   setForm({ ...form, f_price: e.target.value })
                    // }
                    onValueChange={(values) => {
                      const { value } = values;

                      setState({
                        ...state,
                        amount: value,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <label>Number of unit</label>
                  <input
                    type="text"
                    placeholder="Enter number of unit"
                    name="unit"
                    value={unit}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>Password</label>
              <div className="row1">
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Enter account password"
                />
              </div>
            </div>

            <div className="submit">
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: "0.5rem" }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </DrawerFooter>
        </form>
      </Drawer>
    </>
  );
}

export default ClientsHeader;

ClientsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0404061a;
`;

const DrawerBody = styled.div`
  padding: 0px 30px;
  border-bottom: 1px solid #0404061a;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }

  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }
`;

const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;

const OutflowHeaderStyles = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    @media (max-width: 640px) {
      padding-left: 20px;
    }

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  .tabs {
    margin-left: 20px;
    background: #eaeaea;
    display: flex;
    padding: 5px;
    border-radius: 6px;

    a {
      padding: 8px 15px;
      border-radius: 6px;
      text-decoration: none;
      color: #000;
    }
    .active {
      background: #fff;
    }
  }

  .PopButtons {
    display: flex;
  }
`;
const NewReferralButtonStyles = styled.button`
  border-radius: 8px;
  background-color: #ff385c;
  display: flex;
  font-size: 15px;

  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  margin-right: 38px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  @media (max-width: 640px) {
    margin-right: 0px;
  }

  svg {
    margin-top: 9px;
    margin-left: 10px;
  }
  h5 {
    color: white;
  }
`;
