export const commissionsRows = [
  {
    id: 0,
    date: "09/09/2021",
    name: "John Doe",
    clientId: "C8340",
    propertyName: "Grandview Mansion",
    commission: "₦75,000",
    paid: true,
  },
  {
    id: 1,
    date: "13/09/2021",
    name: "Bessie Cooper",
    clientId: "D3924",
    propertyName: "Mayfair Building",
    commission: "₦23,650",
    paid: false,
  },
  {
    id: 2,
    date: "04/09/2021",
    name: "Brooklyn Simmons",
    clientId: "C2831",
    propertyName: "Vidco Home Villa",
    commission: "₦123,050",
    paid: true,
  },
  {
    id: 3,
    date: "01/09/2021",
    name: "Darlene Robertson",
    clientId: "C6237",
    propertyName: "Mangifera Series",
    commission: "₦54,200",
    paid: true,
  },
  {
    id: 4,
    date: "30/08/2021",
    name: "Jacob Jones",
    clientId: "C8340",
    propertyName: "Beach Home Villa",
    commission: "₦435,000",
    paid: false,
  },
  {
    id: 5,
    date: "09/09/2021",
    name: "Darlene Robertson",
    clientId: "C5126",
    propertyName: "Grandview Mansion",
    commission: "₦75,000",
    paid: true,
  },
];

// <<<----- REFERRALS ------->>>
export const InflowRows = [
  {
    id: 0,
    name: "Alexander Cooper",
    phone: "+2348011223344",
    property: "Whiterose Building",
    referrerId: "C8340",
    totalCost: "30, 000",
    amountPaid: "14, 000"
  },
  {
    id: 1,
    name: "Jerome Bell",
    phone: "+2348011223344",
    property: "Inter",
    referrerId: "D3924",
    totalCost: "30, 000",
    amountPaid: "14, 000"
  },
  {
    id: 2,
    name: "Robert Fox",
    phone: "+2348011223344",
    property: "Beach Home Villa",
    referrerId: "C2831",
    totalCost: "30, 000",
    amountPaid: "14, 000"
  },
  {
    id: 3,
    name: "Wade Warren",
    phone: "+2348011223344",
    property: "Whiterose Building",
    referrerId: "C6237",
    totalCost: "30, 000",
    amountPaid: "14, 000"
  },
  {
    id: 4,
    name: "Floyd Miles",
    phone: "+2348011223344",
    property: "Grandview",
    referrerId: "C5126",
    totalCost: "30, 000",
    amountPaid: "14, 000"
  },
];


// <<<----- REFERRALS ------->>>
export const MembershipRows = [
  {
    id: 0,
    date: "13/09/2021",
    name: "Arlene McCoy",
    email: "debra.holt@example.com",
    memberId: "9272",
    referrerId: "3245",
    amountPaid: "25,000",
  },
  {
    id: 1,
    date: "05/07/2021",
    name: "Robert Fox",
    email: "debbie.baker@example.com",
    memberId: "2032",
    referrerId: "7349",
    amountPaid: "25,000",
  },
  {
    id: 2,
    date: "12/04/2021",
    name: "Jacob Jones",
    email: "kenzi.lawson@example.com",
    memberId: "3823",
    referrerId: "4839",
    amountPaid: "25,000",
  },
  {
    id: 3,
    date: "02/11/2021",
    name: "Kathryn Murphy",
    email: "kenzi.lawson@example.com",
    memberId: "7145",
    referrerId: "5739",
    amountPaid: "25,000",
  },
  {
    id: 4,
    date: "10/06/2021",
    name: "Floyd Miles",
    email: "nathan.roberts@example.com",
    memberId: "6407",
    referrerId: "4375",
    amountPaid: "25,000",
  },
];

// <<<----- REFERRALS ------->>>
export const OutflowRows = [
  {
    id: 0,
    date: "5/19/2021",
    client: "Alexander Cooper",
    clientId: "C8340",
    referrer: "Alexander Cooper",
    referrerId: "C8340",
    property: "Whiterose Building",
    commission: "30, 000"
  },
  {
    id: 1,
    date: "5/19/2021",
    client: "Alexander Cooper",
    clientId: "C8340",
    referrer: "Alexander Cooper",
    referrerId: "C8340",
    property: "Whiterose Building",
    commission: "30, 000"
  },
  {
    id: 2,
    date: "5/19/2021",
    client: "Alexander Cooper",
    clientId: "C8340",
    referrer: "Alexander Cooper",
    referrerId: "C8340",
    property: "Whiterose Building",
    commission: "30, 000"
  },
  {
    id: 3,
    date: "5/19/2021",
    client: "Alexander Cooper",
    clientId: "C8340",
    referrer: "Alexander Cooper",
    referrerId: "C8340",
    property: "Whiterose Building",
    commission: "30, 000"
  },
  {
    id: 4,
    date: "5/19/2021",
    client: "Alexander Cooper",
    clientId: "C8340",
    referrer: "Alexander Cooper",
    referrerId: "C8340",
    property: "Whiterose Building",
    commission: "30, 000"
  },
];


// <<<----- REFERRALS ------->>>
export const SalariesRows = [
  {
    id: 0,
    date: "5/19/2021",
    member: "Jerome Bell",
    memberId: "C3823",
    bank: "Zenith Bank PLC",
    accountName: "Jerome Bell",
    accountNumber: "05758758438",
    amountPaid: "70, 000"
  },
  {
    id: 1,
    date: "5/19/2021",
    member: "Jerome Bell",
    memberId: "C3823",
    bank: "Zenith Bank PLC",
    accountName: "Jerome Bell",
    accountNumber: "05758758438",
    amountPaid: "70, 000"
  },
  {
    id: 2,
    date: "5/19/2021",
    member: "Jerome Bell",
    memberId: "C3823",
    bank: "Zenith Bank PLC",
    accountName: "Jerome Bell",
    accountNumber: "05758758438",
    amountPaid: "70, 000"
  },
  {
    id: 3,
    date: "5/19/2021",
    member: "Jerome Bell",
    memberId: "C3823",
    bank: "Zenith Bank PLC",
    accountName: "Jerome Bell",
    accountNumber: "05758758438",
    amountPaid: "70, 000"
  },
  {
    id: 4,
    date: "5/19/2021",
    member: "Jerome Bell",
    memberId: "C3823",
    bank: "Zenith Bank PLC",
    accountName: "Jerome Bell",
    accountNumber: "05758758438",
    amountPaid: "70, 000"
  },
];



// <<<----- REFERRALS ------->>>
export const ClientsRows = [
  {
    id: 0,
    date: "5/19/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    clientId: "C8340",
    status: "ACTIVE",
  },
  {
    id: 1,
    date: "5/19/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    clientId: "C8340",
    status: "INACTIVE",
  },
  {
    id: 2,
    date: "5/19/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    clientId: "C8340",
    status: "ACTIVE",
  },
  {
    id: 3,
    date: "5/19/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    clientId: "C8340",
    status: "INACTIVE",
  },
  {
    id: 4,
    date: "5/19/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    clientId: "C8340",
    status: "ACTIVE",
  },
];


// <<<----- REFERRALS ------->>>
export const PayoutRows = [
  {
    id: 0,
    date: "5/19/2021",
    client: "Jerome Bell",
    clientId: "C8340",
    referrer: "Robert Fox",
    referrerId: "8943",
    property: "Grandview Mansion (Phase 2)",
    commission: "₦30,000"
  },
  {
    id: 1,
    date: "5/19/2021",
    client: "Jerome Bell",
    clientId: "C8340",
    referrer: "Robert Fox",
    referrerId: "8943",
    property: "Grandview Mansion (Phase 2)",
    commission: "₦30,000"
  },
  {
    id: 2,
    date: "5/19/2021",
    client: "Jerome Bell",
    clientId: "C8340",
    referrer: "Robert Fox",
    referrerId: "8943",
    property: "Grandview Mansion (Phase 2)",
    commission: "₦30,000"
  },
  {
    id: 3,
    date: "5/19/2021",
    client: "Jerome Bell",
    clientId: "C8340",
    referrer: "Robert Fox",
    referrerId: "8943",
    property: "Grandview Mansion (Phase 2)",
    commission: "₦30,000"
  },
  {
    id: 4,
    date: "5/19/2021",
    client: "Jerome Bell",
    clientId: "C8340",
    referrer: "Robert Fox",
    referrerId: "8943",
    property: "Grandview Mansion (Phase 2)",
    commission: "₦30,000"
  },
];


// <<<----- REFERRALS ------->>>
export const DiscountedSalariesRows = [
  {
    id: 0,
    date: "5/19/2021",
    name: "Cameron Williamson",
    memberId: "C8340",
    bank: "Zenith Bank",
    accountName: "Leslie Alexander",
    accountNumber: "34758360480",
    amount: "₦30,000"
  },
  {
    id: 1,
    date: "5/19/2021",
    name: "Cameron Williamson",
    memberId: "C8340",
    bank: "Zenith Bank",
    accountName: "Leslie Alexander",
    accountNumber: "34758360480",
    amount: "₦30,000"
  },
  {
    id: 2,
    date: "5/19/2021",
    name: "Cameron Williamson",
    memberId: "C8340",
    bank: "Zenith Bank",
    accountName: "Leslie Alexander",
    accountNumber: "34758360480",
    amount: "₦30,000"
  },
  {
    id: 3,
    date: "5/19/2021",
    name: "Cameron Williamson",
    memberId: "C8340",
    bank: "Zenith Bank",
    accountName: "Leslie Alexander",
    accountNumber: "34758360480",
    amount: "₦30,000"
  },
  {
    id: 4,
    date: "5/19/2021",
    name: "Cameron Williamson",
    memberId: "C8340",
    bank: "Zenith Bank",
    accountName: "Leslie Alexander",
    accountNumber: "34758360480",
    amount: "₦30,000"
  },
];



// <<<----- REFERRALS ------->>>
export const MembersRows = [
  {
    id: 0,
    date: "5/19/2021",
    name: "Jerome Bell",
    email: "willie.jennings@example.com",
    level: "Associate",
    referrals: "55",
    referrerId: "8943",
    downlines: "86",
  },
  {
    id: 1,
    date: "5/19/2021",
    name: "Jerome Bell",
    email: "willie.jennings@example.com",
    level: "Associate",
    referrals: "55",
    referrerId: "8943",
    downlines: "86",
  },
  {
    id: 2,
    date: "5/19/2021",
    name: "Jerome Bell",
    email: "willie.jennings@example.com",
    level: "Associate",
    referrals: "55",
    referrerId: "8943",
    downlines: "86",
  },
  {
    id: 3,
    date: "5/19/2021",
    name: "Jerome Bell",
    email: "willie.jennings@example.com",
    level: "Associate",
    referrals: "55",
    referrerId: "8943",
    downlines: "86",
  },
  {
    id: 4,
    date: "5/19/2021",
    name: "Jerome Bell",
    email: "willie.jennings@example.com",
    level: "Associate",
    referrals: "55",
    referrerId: "8943",
    downlines: "86",
  },
];


// <<<----- REFERRALS ------->>>
export const ReferralRankingRows = [
  {
    id: 0,
    name: "Ralph Edwards",
    phone: "+2348011223344",
    referralId: "8943",
    downlines: "86",
  },
  {
    id: 1,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    downlines: "86",
  },
  {
    id: 2,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    downlines: "86",
  },
  {
    id: 3,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    downlines: "86",
  },
  {
    id: 4,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    downlines: "86",
  },
];


// <<<----- REFERRALS ------->>>
export const SaleRankingRows = [
  {
    id: 0,
    name: "Ralph Edwards",
    phone: "+2348011223344",
    referralId: "8943",
    unitSold: 35,
    amountSold: "₦850,000,000"
  },
  {
    id: 1,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    unitSold: 35,
    amountSold: "₦850,000,000"
  },
  {
    id: 2,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    unitSold: 35,
    amountSold: "₦850,000,000"
  },
  {
    id: 3,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    unitSold: 35,
    amountSold: "₦850,000,000"
  },
  {
    id: 4,
    name: "Jerome Bell",
    phone: "+2348011223344",
    referralId: "8943",
    unitSold: 35,
    amountSold: "₦850,000,000"
  },
];

// <<<----- REFERRALS ------->>>
export const ClientRankingRows = [
  {
    id: 0,
    name: "Ralph Edwards",
    phone: "+2348011223344",
    clientId: "8943",
    unitBought: 35,
    totalAmount: "₦850,000,000"
  },
  {
    id: 1,
    name: "Jerome Bell",
    phone: "+2348011223344",
    clientId: "8943",
    unitBought: 35,
    totalAmount: "₦850,000,000"
  },
  {
    id: 2,
    name: "Jerome Bell",
    phone: "+2348011223344",
    clientId: "8943",
    unitBought: 35,
    totalAmount: "₦850,000,000"
  },
  {
    id: 3,
    name: "Jerome Bell",
    phone: "+2348011223344",
    clientId: "8943",
    unitBought: 35,
    totalAmount: "₦850,000,000"
  },
  {
    id: 4,
    name: "Jerome Bell",
    phone: "+2348011223344",
    clientId: "8943",
    unitBought: 35,
    totalAmount: "₦850,000,000"
  },
];



// <<<----- DOWNLINES - FIRST GEN ------->>>
// export const downlinesRows = [
//   {
//     id: 0,
//     date: "13/09/2021",
//     name: "Alexander Cooper",
//     phone: "+2348011223344",
//     email: "willie.jennings@example.com",
//     level: "Associate",
//     memberId: "C8340",
//   },
//   {
//     id: 1,
//     date: "09/09/2021",
//     name: "Jerome Bell",
//     phone: "+2348011223344",
//     email: "bill.sanders@example.com",
//     level: "Pre-associate",
//     memberId: "D3924",
//   },
//   {
//     id: 2,
//     date: "04/09/2021",
//     name: "Robert Fox",
//     phone: "+2348011223344",
//     email: "nathan.roberts@example.com",
//     level: "Premium member",
//     memberId: "C2831",
//   },
//   {
//     id: 3,
//     date: "01/09/2021",
//     name: "Wade Warren",
//     phone: "+2348011223344",
//     email: "debra.holt@example.com",
//     level: "Sapphire Director",
//     memberId: "C6237",
//   },
//   {
//     id: 4,
//     date: "30/08/2021",
//     name: "Floyd Miles",
//     phone: "+2348011223344",
//     email: "deanna.curtis@example.com",
//     level: "Elite",
//     memberId: "C5126",
//   },
//   {
//     id: 5,
//     date: "30/08/2021",
//     name: "Eleanor Pena",
//     phone: "+2348011223344",
//     email: "sara.cruz@example.com",
//     level: "Executive",
//     memberId: "C3290",
//   },
//   {
//     id: 6,
//     date: "30/08/2021",
//     name: "Brooklyn Simmons",
//     phone: "+2348011223344",
//     email: "debbie.baker@example.com",
//     level: "Director",
//     memberId: "C1297",
//   },
//   {
//     id: 7,
//     date: "30/08/2021",
//     name: "Cameron Williamson",
//     phone: "+2348011223344",
//     email: "willie.jennings@example.com",
//     level: "Sapphire Director",
//     memberId: "C6237",
//   },
// ];

export const downlinesRows = [];
