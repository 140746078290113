import React, { useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { menus } from "helpers/datasets/asideMenus";
import { makeStyles, useTheme } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { HiLogout } from "react-icons/hi";
import logo from "../../assets/logo.png";
import logoSymbol from "../../assets/logo-symbol.png";
import { useState } from "react";
import styled from "@emotion/styled";
import { Menu } from "@mui/icons-material";
import { Cancel } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  aside: {
    width: "25rem",
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    paddingTop: "5em",
    height: "100vh",
    boxShadow: "5px -5px 7px #eee",
    position: "fixed",
    overflowY: "scroll",
    zIndex: theme.zIndex.appBar + 1,
    background: theme.palette.primary.main,
    transition: "all .25s ease-in-out",

    // "&:hover": {
    //   overflowY: "scroll",
    // },

    "& .MuiListItemButton-root": {
      marginBottom: "2em",

      "&:hover": {
        backgroundColor: theme.palette.common.darkerGrey,
        borderRadius: 5,

        "& .menu-icon": {
          fill: "#fff",
        },
      },
    },

    "& .MuiListItemIcon-root": {
      minWidth: 50,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "2rem",

      "&:hover": {
        color: theme.palette.common.red,
      },
    },

    "& .MuiTypography-root": {
      fontSize: "15px",
      color: "#fff",
    },

    "& .MuiListItemButton-root.Mui-selected": {
      backgroundColor: theme.palette.common.darkerGrey,
      borderRadius: ".5rem",

      "&:hover": {
        backgroundColor: theme.palette.common.darkerGrey,
      },

      "& .MuiListItemIcon-root": {
        color: theme.palette.common.red,
      },

      "& .MuiTypography-root": {
        fontSize: "15px",
        color: "#fff",
      },
    },

    "&::-webkit-scrollbar": {
      width: ".85rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 14px rgba(0, 0, 0, 0.2)",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: ".5rem",
      background: theme.palette.common.darkerGrey,
    },
  },
  dropDownMenu: {
    "&.MuiListItemButton-root": {
      "&:hover": {
        background: "transparent",
      },
    },
  },
  logoWrapper: {
    width: '200px',
    paddingTop: '1em',
    paddingBottom: '3em',
    // paddingLeft: "7em",
  },
  arrowIconWrapper: {
    background: "#262626",
    border: "1px solid #fff",
    display: "inline-block",
    padding: ".25rem",
    borderRadius: ".5rem",
    position: "fixed",
    left: "26.7rem",
    top: "5rem",
    cursor: "pointer",
    zIndex: theme.zIndex.appBar + 2,
    color: "#fff",
    transition: "left .25s ease-in-out",

    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  accordion: {
    "&.MuiPaper-root": {
      background: "transparent",
      marginBottom: "2rem",

      "&::before": {
        background: "transparent",
      },
    },
  },
  accordionSummary: {
    "&.MuiAccordionSummary-root": {
      padding: 0,
      paddingRight: 5,

      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },

      "&.Mui-expanded": {
        minHeight: "auto",
      },
    },

    "& .MuiAccordionSummary-content": {
      margin: 0,

      "&.Mui-expanded": {
        margin: 0,
      },
    },

    "&:hover": {
      background: theme.palette.common.darkerGrey,
      borderRadius: 5,
    },
  },
  accordionDetails: {
    marginLeft: "5rem",
  },

  dropDownSelected: {
    "&.MuiAccordionSummary-root": {
      background: "theme.palette.common.darkerGrey",
      borderRadius: 5,
    },
  },
}));

const SideMenu = (props) => {
  const [sideBar, setsideBar] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const {
    selectedMenu,
    setSelectedMenu,
    menuSlideIn,
    setMenuSlideIn,
    selectedDropdown,
    setSelectedDropdown,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();

  useLayoutEffect(() => {
    [...menus].forEach((menu) => {
      switch (location.pathname) {
        case `/${menu.path}`:
          if (menu.id !== selectedMenu) {
            setSelectedMenu(menu.id);
          }
          break;
        default:
          break;
      }
    });

    // eslint-disable-next-line
  }, [selectedMenu]);

  useLayoutEffect(() => {
    [...menus].map((menu) => {
      return (
        menu.hasOwnProperty("dropDowns") &&
        menu.dropDowns.forEach((dropdown) => {
          switch (location.pathname) {
            case `/${dropdown.path}`:
              if (dropdown.subId !== selectedDropdown) {
                setSelectedDropdown(dropdown.subId);
                setSelectedMenu(menu.id);
              }
              break;
            default:
              break;
          }
        })
      );
    });

    // eslint-disable-next-line
  }, [selectedDropdown]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      handleLogout();
      document.location.assign("/");
    } else {
      if (localStorage.getItem("user")) {
        if (!user) {
          setUser(JSON.parse(localStorage.getItem("user")));
        }
      } else {
        handleLogout();
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    setUser(null);
  };
  const handleHideNav = () => {
    let maxWidth = window.matchMedia("(max-width: 768px)");
    if (maxWidth.matches) {
      setsideBar((prev) => false);
    }
  };
  return (
    <ClickAwayListener onClickAway={handleHideNav}>
      <div>
        <Aside
          className={`${classes.aside} ${
            menuSlideIn ? classes.slideInMenu : classes.slideOutMenu
          }`}
          style={{
            width: menuSlideIn ? "5rem" : "25rem",
            display: sideBar ? "block" : "none",
          }}
        >
          <div className="exit">
            <Cancel
              onClick={() => setsideBar((prev) => !prev)}
              style={{ color: "white", fontSize: "25px" }}
            />
          </div>
          <div className={classes.logoWrapper}>
            {/* <Typography variant="h5">Logo</Typography> */}
            {menuSlideIn ? (
              <img src={logoSymbol} alt="logo" />
            ) : (
              <img src={logo} alt="logo" style={{ width: "100% " }} />
            )}
          </div>
          <div
            className={`arrowIconWrapper ${classes.arrowIconWrapper}`}
            onClick={() => setMenuSlideIn((prev) => !prev)}
            style={{ left: menuSlideIn ? "6.5rem" : undefined }}
          >
            {menuSlideIn ? (
              <ArrowForwardIosIcon style={{ fontSize: "1.6rem" }} />
            ) : (
              <ArrowBackIosNewIcon style={{ fontSize: "1.6rem" }} />
            )}
          </div>
          <List>
            {menus.map((menu) =>
              menu.hasOwnProperty("dropDowns") ? (
                <Accordion
                  elevation={0}
                  classes={{ root: classes.accordion }}
                  key={menu.id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{ root: classes.accordionSummary }}
                    className={`${
                      selectedMenu === menu.id ? classes.dropDownSelected : null
                    }`}
                  >
                    <ListItemButton
                      disableRipple
                      component={Link}
                      to={document.location.pathname}
                      style={{
                        paddingLeft: menuSlideIn ? 2 : undefined,
                        paddingRight: menuSlideIn ? 2 : undefined,
                        justifyContent: menuSlideIn ? "center" : undefined,
                        marginBottom: 0,
                      }}
                      className={classes.dropDownMenu}
                    >
                      <ListItemIcon
                        style={{ minWidth: menuSlideIn ? "auto" : undefined }}
                      >
                        {React.createElement(menu.icon, {
                          size: 30,
                          className: "menu-icon",
                          style: { fill: "#fff", stroke: "#fff" },
                        })}
                      </ListItemIcon>

                      {!menuSlideIn ? (
                        <ListItemText fontSize="15px" onClick={handleHideNav}>
                          {menu.title}
                        </ListItemText>
                      ) : null}
                    </ListItemButton>
                  </AccordionSummary>
                  {!menuSlideIn ? (
                    <AccordionDetails
                      classes={{ root: classes.accordionDetails }}
                    >
                      {menu.dropDowns.map((dropdown) => (
                        <ListItemButton
                          key={dropdown.subId}
                          style={{
                            marginBottom: 0,
                            borderRadius: 5,
                            background:
                              location.pathname === `/${dropdown.path}` &&
                              selectedDropdown === dropdown.subId
                                ? theme.palette.common.darkerGrey
                                : null,
                          }}
                          component={Link}
                          to={dropdown.path}
                          onClick={() => {
                            setSelectedDropdown(dropdown.subId);
                            setSelectedMenu(menu.id);
                          }}
                        >
                          <ListItemText onClick={handleHideNav}>
                            {dropdown.title}
                          </ListItemText>
                        </ListItemButton>
                      ))}
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              ) : (
                <ListItemButton
                  disableRipple
                  key={menu.id}
                  onClick={() => {
                    setSelectedMenu(menu.id);
                  }}
                  selected={selectedMenu === menu.id}
                  component={Link}
                  to={menu.path}
                  style={{
                    paddingLeft: menuSlideIn ? 0 : undefined,
                    paddingRight: menuSlideIn ? 0 : undefined,
                    justifyContent: menuSlideIn ? "center" : undefined,
                  }}
                >
                  <ListItemIcon
                    style={{ minWidth: menuSlideIn ? "auto" : undefined }}
                  >
                    {React.createElement(menu.icon, {
                      size: 30,
                      className: "menu-icon",
                      style: { fill: "#fff", stroke: "#fff" },
                    })}
                  </ListItemIcon>

                  {!menuSlideIn ? (
                    <ListItemText onClick={handleHideNav}>
                      {menu.title}
                    </ListItemText>
                  ) : null}
                </ListItemButton>
              )
            )}
            <ListItemButton
              disableRipple
              onClick={handleLogout}
              classes={{ root: classes.logout }}
            >
              <ListItemIcon>
                <HiLogout size={20} color="#fff" />
              </ListItemIcon>

              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </List>
          <br />
          <br />
          <br />
        </Aside>
        <Svg>
          <Menu
            style={{ color: "#c4c4c4", fontSize: "30px" }}
            onClick={() => setsideBar((prev) => !prev)}
          />
        </Svg>
      </div>
    </ClickAwayListener>
  );
};

SideMenu.propTypes = {
  selectedMenu: PropTypes.number.isRequired,
  setSelectedMenu: PropTypes.func.isRequired,
  setSelectedDropdown: PropTypes.func.isRequired,
  selectedDropdown: PropTypes.number.isRequired,
  setMenuSlideIn: PropTypes.func.isRequired,
  menuSlideIn: PropTypes.bool.isRequired,
};

export default SideMenu;

const Aside = styled.div`
  min-height: 100vh;
  top: 0px;
  @media (max-width: 800px) {
    display: none;
    position: fixed;
  }
  .arrowIconWrapper {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .exit {
    display: none;
    @media (max-width: 800px) {
      display: block;
      background: inherit;
      float: right;
      margin-top: -30px;
    }
  }
`;

const Svg = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 18px;
    border-radius: 8px;
    margin-left: 5px;
    border: 1px solid #c4c4c4;
  }
`;
