import { createTheme } from "@mui/material/styles";

const dark = "#171717";
const red = "#FF385C";
const green = "#28C52B";
const blue = "#128EFF";
const yellow = "#F8920F";
const grey = "#7D7D7E";
const darkerGrey = "#262626";
const deepBlue = "#8469FF";
const lighterGrey = "#0404061A";

export const theme = createTheme({
  palette: {
    common: {
      dark,
      grey,
      darkerGrey,
      deepBlue,
      lighterGrey,
    },
    primary: {
      main: dark,
    },
    secondary: {
      main: red,
    },
    error: {
      main: red,
    },
    success: {
      main: green,
    },
    info: {
      main: blue,
    },
    warning: {
      main: yellow,
    },
  },
  typography: {
    fontFamily: ["inter", "Roboto", "sans-serif"].join(", "),
    fontSize: 10,
    htmlFontSize: 10,
    h1: {
      fontSize: "3rem",
      color: dark,
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      color: dark,
      fontWeight: 600,
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.85rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },

    body1: {
      fontSize: "1.6rem",
      fontWeight: 500,
      lineHeight: 1.7,
      color: dark,
    },
    body2: {
      fontSize: "1.4rem",
      fontWeight: 500,
      lineHeight: 1.85,
    },
    btn: {
      fontSize: "1.5rem",
      textTransform: "none",
      height: "5rem",
      borderRadius: 10,
      boxShadow: "0px 0px 4px -1px rgba(71,64,71,0.63)",
    },

    input: {
      width: "100%",
      border: "1px solid",
      borderRadius: ".5rem",
      padding: "14px",
      fontSize: "1.6rem",
      color: dark,
      fontWeight: 600,

      "&:focus": {
        outline: "none",
      },

      "&::placeholder": {
        color: grey,
        fontWeight: 500,
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#fff",
          boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: "-.9rem",
        },
      },
    },
  },
});
