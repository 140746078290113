import React from "react";
import styled from "@emotion/styled";

const MessagesContent = ({
  authorImg,
  authorName,
  email,
  title,
  content,
  date,
  img,
}) => {
  return (
    <div>

      <MessageHeader>
        <img src={authorImg} alt={title} />
        <div className="title">
          <div>
            <h3>{authorName}</h3>
            <h4>{email}</h4>
          </div>
          <p className="date">{date}</p>
        </div>
      </MessageHeader>
      <MessageBody>
        <h1 className="heading">{title}</h1>
        <p className="text-content">{content}</p>
        <div className="img">
          <img src={img} alt={title} />
        </div>
      </MessageBody>
    </div>
  );
};

export default MessagesContent;

const MessageHeader = styled.div`
  display: flex;
  padding: 20px 40px;
  border-bottom: 1px solid #e5e5e5;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 10px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    flex: 1;

    h4 {
      color: #7d7d7e;
      font-weight: normal;
    }

    .date {
      font-size: 12px;
      color: #7d7d7e;
    }
  }
`;

const MessageBody = styled.div`
  padding: 40px;

  .text-content {
    margin: 20px 0px;
    font-size: 15px;
  }

  .img {
    width: 100%;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;

    img {
      width: 460px;
      max-width: 70%;
    }
  }
`;
