export const messagesdata = [
  {
    id: 1,
    authorImg: "https://i.postimg.cc/mrGqntwr/image.png",
    authorName: "Williams Olayemi",
    email: "willie@zbm.ng",
    title: "Happy Birthday Mr. Olayemi",
    content:
      "We congrulate you today on your Golden Jubilee; 50th Birthday ceremony. We are ve...",
    date: "8:00 AM (5 hours ago)",
    dateAbr: "Yesterday",
    img: "https://i.postimg.cc/bYTyQrmT/image.png",
  },
  {
    id: 2,
    authorImg: "https://i.postimg.cc/yN44zfVs/image.png",
    authorName: "Darlene Robertson",
    email: "darlene@zbm.ng",
    title: "Happy Birthday Mr. Robertson",
    content:
      "We congrulate you today on your Golden Jubilee; 50th Birthday ceremony. We are ve...",
    date: "8:00 AM (5 hours ago)",
    dateAbr: "5d",
    img: "https://i.postimg.cc/7hgVtcKf/image.png",
  },
  {
    id: 3,
    authorImg: "https://i.postimg.cc/Xqvtgmgc/image.png",
    authorName: "Dianne Russell",
    email: "dianne@zbm.ng",
    title: "Congrats on your promotion Robertson",
    content:
      "We congrulate you today on your Golden Jubilee; 50th Birthday ceremony. We are ve...",
    date: "8:00 AM (5 hours ago)",
    dateAbr: "1 week ago",
    img: "https://i.postimg.cc/fbPsgrqj/image.png",
  },
];

export const messagesdata1 = [];
