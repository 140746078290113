import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { MembershipHeadCells } from "../../helpers/datasets/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { MembershipRows } from "helpers/datasets/table-rows";
import { dateMoment } from "../utils/Time";
import styled from "@emotion/styled";
import Loader from "components/Utilities/Loader";

import {
  getAllMembershipProperties,
  getAllMembersByID,
  getUserByReferralCode,
} from "services/properties";
import prettyMoney from "pretty-money";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const MembershipTable = ({ state, setState }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [emails, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (state) {
      state.map(async (el) => {
        getEmail(el.id).then(async(response) => {

          // console.log("from get", response);
          const data = await getUserByReferralCode("user",response.referrerCode);
          // console.log('res', data);
            if (response?.email) {
              setEmail((prev) => [
                ...prev,
                {
                  id: el.id,
                  email: response.email,
                  referrerCode: response.referrerCode,
                  myReferralCode: response.myReferralCode,
                  firstName: response.firstName,
                  lastName: response.lastName,

                },
              ]);
            } else {
              setEmail((prev) => [
                ...prev,
                {
                  id: el.id,
                  email: "no Emails",
                  referrerCode: "no referrerCode",
                  myReferralCode:"nothing yet",
                  firstName: '',
                  lastName:'no name'
                  
                },
              ]);
            }
          })
        
        });
      // console.log("state", state.length, emails.length);
      // setValues(state);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    if (emails.length === state.length) {
      const newData = state.map((el) => ({
        ...el,
        email: emails.filter((ele) => ele.id === el.id)[0].email || "no email",
        referrerCode:
          emails.filter((ele) => ele.id === el.id)[0].referrerCode ||
          "no referrerCode",
        myReferralCode:
          emails.filter((ele) => ele.id === el.id)[0].myReferralCode ||
          "no referrerCode",
          firstName:
          emails.filter((ele) => ele.id === el.id)[0].firstName ||
          "",
          lastName:
          emails.filter((ele) => ele.id === el.id)[0].lastName ||
          "no name",
      }));
      setValues(newData);
      setLoading(false);
    }
  }, [state, emails.length]);

  let getEmail = async (id) => {
    return await getAllMembersByID(id);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  // console.log("this is emailList", email);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          headCells={MembershipHeadCells}
          rows={values}
          page={page}
          paginationLabel="Showing"
          hasCheckbox={false}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        >
          {values
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const {
                id,
                createdAt,
                fullName,
                email,
                referrerCode,
                ammountJustPaid,
                myReferralCode,
                firstName,
                lastName
              } = row;

              return (
                <TableRow hover tabIndex={-1} key={id}>
                  <TableCell className={classes.tableCell}>
                    <input type="checkbox" />
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {dateMoment(createdAt)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                  {firstName ? `${firstName} ${lastName}` : "No name"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{email}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {myReferralCode}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {referrerCode ? referrerCode : "no Id"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prettyMoney(prettyDollarConfig, ammountJustPaid)}
                  </TableCell>

                  {/* <TableCell className={classes.tableCell}>
                  <div
                    style={{
                      flex: "0.8",

                      width: "95%",
                      margin: "-10px",
                      display: "flex",
                    }}
                  >
                    <EditDelBtn
                      onClick={() => getClient(id)}
                      style={{ cursor: "pointer" }}
                    >
                      <IconEdit /> Edit Client
                    </EditDelBtn>
                    <EditDelBtn
                      className="delete"
                      onClick={() => handleOpenDelete(id)}
                    >
                      <IconTrash /> Delete Client
                    </EditDelBtn>{" "}
                  </div>
                </TableCell> */}
                </TableRow>
              );
            })}
        </EnhancedTable>
      )}
    </>
  );
};

export default MembershipTable;

const NairaSign = styled.span`
  text-decoration: line-through;
  text-decoration-style: double;
`;
