import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Loader from "components/Utilities/Loader";
import styled from "@emotion/styled";
import MiniSearchBar from "components/layouts/MiniSearchBar";
import EmptyTable from "components/utils/table-layouts/EmptyTable";
import { IconExport, IconFilter } from "../../assets/icons";
import ClientsHeader from "components/layouts/ClientsHeader";
import ClientsTable from "components/layouts/ClientsTable";
import { getAllClients, getAllClientsS } from "services/properties";
import { CSVLink } from "react-csv";
const Clients = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState([]);
  const [c, setC] = useState([]);

  const [popEditDelete, setPopEditDelete] = useState(false);
  const [regClient, setRegClient] = useState(false);
  const [editClient, setEditClient] = useState();
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(0);

  const handlePopoverOpen = (id) => setPopEditDelete(!popEditDelete);

  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();

  const handlePopoverClose = () => {
    setPopEditDelete(false);
  };
  useEffect(() => {
    (async () => {
      const data = await getAllClients();
      if (data) {

        setState(data.clients.clients);
        setPage(data.clients.current_page);
        setLoading(false);
      }
    })();
  }, []);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    (async () => {
      const data = await getAllClientsS(e.target.value);
      if (data) {
        console.log(data.clients.clients, searchQuery, "from ser c");
        setState(data.clients.clients);
        setPage(data.clients.current_page);
        setLoading(false);
      }
    })();
  };

  console.log(state, "client state");
  const headers = [
    { key: "address", label: "adresss" },
    { key: "adminId", label: "adminId" },
    { key: "city", label: "city" },
    { key: "clientId", label: "clientId" },
    { key: "createdAt", label: "createdAt" },
    { key: "dateOfBirth", label: "dateOfBirth" },
    { key: "deletedAt", label: "deletedAt" },
    { key: "email", label: "email" },
    { key: "gender", label: "gender" },
    { key: "lastName", label: "lastName" },
    { key: "firstName", label: "firstName" },
    { key: "mobile", label: "mobile" },
    { key: "numberOfunits", label: "numberOfunits" },
    { key: "propertyPrice", label: "propertyPrice" },
    { key: "referrerFullname", label: "referrerFullname" },
    { key: "referrerId", label: "referrerId" },
    { key: "state", label: "state" },
    { key: "updatedAt", label: "updatedAt" },
  ];

  const csvReport = {
    fileName: "whiterose.csv",
    headers,
    data: state,
  };

  return (
    <ReferralsStyles>
      <ClientsHeader
        title="Clients"
        subTitle="This displays the registered clients"
        setRegClient={setRegClient}
        regClient={regClient}
        editClient={editClient}
        edit={edit}
        id={id}
        setState={setState}
        setPage={setPage}
        setEdit={setEdit} // popEditDelete={popEditDelete}
      />
      <hr />
      <div className="search-filter-export">
        <div className="inputSearch">
          <MiniSearchBar
            name={searchQuery}
            color="#fff"
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search client, name or property..."
            className="longSearch"
          />
        </div>
        <div className="filter-export">
          <div className="filter">
            <IconFilter />

            {/* }/> */}
            <h3>Filter</h3>
          </div>
          <div className="export">
            <IconExport />
            <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                color: "gray",
              }}
            >
              <h3 style={{ color: "black" }}>Export</h3>
            </CSVLink>
          </div>
        </div>
      </div>
      <hr />

      <div className="table">
        <Grid container>
          {loading ? (
            <Loader />
          ) : state && state.length > 0 ? (
            <ClientsTable
              state={state}
              popEditDelete={popEditDelete}
              regClient={regClient}
              setRegClient={setRegClient}
              setEdit={setEdit}
              setEditClient={setEditClient}
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              setState={setState}
              setPage={setPage}
              page={page}
              id={id}
              setId={setId}
            />
          ) : (
            <EmptyTable />
          )}
        </Grid>
      </div>
    </ReferralsStyles>
  );
};

export default Clients;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  .table {
    margin: 20px 40px;

    @media (max-width: 640px) {
      margin: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 125vh;
    }
  }

  .search-filter-export {
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
