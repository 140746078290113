import React from "react";
import { Modal } from "@mui/material";
import styled from "@emotion/styled";
import { IconCaution } from "assets/icons";

const DeleteModals = ({ deleteProperty, handleCloseDelete, handleDelete }) => {
  return (
    <Modal
      open={deleteProperty}
      onClose={handleCloseDelete}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBody>
        <ModalHeader>
          <IconCaution />
          <p>Are you sure you want to delete this property?</p>
        </ModalHeader>
        <ModalFooter>
          <button onClick={handleCloseDelete}>Cancel</button>
          <button className="confirm" onClick={handleDelete}>
            Confirm
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModals;

const ModalFooter = styled.div`
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  button {
    background: #f5f5f5;
    padding: 12px;
    border: none;
    outline: none;
    flex: 1;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 8px;

    &.confirm {
      background: #ff385c;
      color: #fff;
    }
  }
`;
const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #fff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;

  svg {
    font-size: 38px;
    color: #f8920f;
    margin: 20px;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }
`;
