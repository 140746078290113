import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { MembersHeadCells } from "../../helpers/datasets/table-header-data";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dateMoment } from "../utils/Time";
import DeleteModals from "components/utils/DeleteModals";
import {
  deleteAComission,
  getAllMembers,
  setUserActiveOrInActive,
} from "services/properties";
import { Phone } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const handleOpenDelete = (id) => {
  // setDeleteProperty(true);
  // setId(id);
};

const MemberTable = ({ state, setState }) => {
  const [values, setValues] = useState([]);
  const [userA, setUserA] = useState(true);
  const [mID, setmID] = useState(null);
  const [mStatus, setmStatus] = useState(null);

  useEffect(() => {
    setValues(state);
  }, [state]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [id] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);

  const handleStatusUpdate = (id, status) => () => {
    updateStatus(id, status);
  };

  const updateStatus = useCallback(
    async (id, status) => {
      let newStatus;
      if (status == "inactive") {
        newStatus = "active";
      } else {
        newStatus = "inactive";
      }

      await setUserActiveOrInActive(id, newStatus);

      const value = await getAllMembers();
      setState(value.users.users);
    },
    [setState]
  );

  return (
    <>
      <EnhancedTable
        headCells={MembersHeadCells}
        rows={values}
        page={page}
        paginationLabel="Showing"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {values
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => {
            const {
              created_on,
              id,
              firstName,
              lastName,
              email,
              level,
              myReferralCode,
              referredUsers,
              status,
              referrerCode,
              mobile, 
            
            } = row;
            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell className={classes.tableCell}>
                  <input type="checkbox" />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {dateMoment(created_on)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {firstName ? `${firstName} ${lastName}` : "No name"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {mobile ? `+ ${mobile}` : "no phone"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {email.length <= 20 ? email : `${email.slice(0, 20)}...`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {myReferralCode}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {level.replace(/_/g, " ")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {referredUsers.length}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {referrerCode}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {status === "inactive" ? (
                    <div className="SIA">{status}</div>
                  ) : (
                    <div className="SA">{status}</div>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {status === "inactive" ? (
                    <div
                      className="activeDiv"
                      onClick={handleStatusUpdate(id, status)}
                    >
                      activate
                    </div>
                  ) : (
                    <div
                      className="dActiveDiv"
                      onClick={handleStatusUpdate(id, status)}
                    >
                      deactivate
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </EnhancedTable>
      <DeleteModals
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
    </>
  );
};

export default MemberTable;

// const StatusBtn = styled.button`
//   padding: 7px 10px;
//   border-radius: 6px;
//   border: none;
//   outline: none;
//   font-size: 13px;

//   &.ACTIVE {
//     background: #1ec6771a;
//     color: #1ec677;
//   }
//   &.INACTIVE {
//     background: #ed32371a;
//     color: #ed3237;
//   }
// `;
// const Popover = styled.div`
//   position: absolute;
//   top: auto;
//   right: 90px;
//   background: #fff;
//   border-radius: 7px;
//   z-index: 1000;
//   box-shadow: 2px 2px 2px #cccccc1a;
// `;
