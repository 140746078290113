import React, { useState, useRef, useEffect } from "react";
import { Cancel } from "@mui/icons-material";
import {
  Card,
  Drawer,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { IconEdit, IconTrash } from "assets/icons";
import {
  DeleteSingleProperty,
  EditSingleProperty,
  GetProperties,
} from "services/properties";
// import DeleteModal from "../utils/DeleteModal";

import prettyMoney from "pretty-money";
import NumberFormat from "react-number-format";
import DeleteModals from "components/utils/DeleteModals";
import axios from "axios";
import { BASE_URL } from "services/BASE_URL";

const token = localStorage.getItem("token");

const useStyles = makeStyles((theme) => ({
  address: {
    lineHeight: "18px",
  },
}));

const DashboardBuildingCard = ({
  building,
  setShowBuilding,
  setPrevBuilding,
  setDeletePro,
}) => {
  const classes = useStyles();
  const [deleted, setDeleted] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [popEditDelete, setPopEditDelete] = useState(false);
  const [editProperty, setEditProperty] = useState(false);
  const [success, setSuccess] = useState("");
  const [pending, setPending] = useState(false);
  const [allFiles, setAllfiles] = useState(null);
  const [allNewFiles, setAllNewFile] = useState(null);

  const [form, setForm] = useState({
    f_name: building?.name,
    f_category: building?.category || "Apartments",
    f_title: building?.title,
    f_location: building?.address,
    f_state: building?.state,
    f_city: building?.city,
    f_description: building?.description,
    f_price: building?.price,
    f_commission: building?.commission,
    f_files: building?.images,
    f_banner: building?.banner,
    propertyDoc:building?.propertyDoc
  });
  const {
    f_name,
    f_category,
    f_title,
    f_location,
    f_state,
    f_city,
    f_description,
    f_price,
    f_commission,
    f_files,
    f_banner,
    propertyDoc
  } = form;

  console.log("buildings", allFiles);
  const handleCloseDelete = () => setDeleteProperty(false);
  const handleOpenDelete = () => setDeleteProperty(true);
  const handleOpenEdit = () => setEditProperty(true);
  const handleCloseEdit = () => setEditProperty(false);

  const handlePopoverOpen = (id) => setPopEditDelete(true);

  const handlePopoverClose = () => {
    setPopEditDelete(false);
  };

  const handleDelete = () => {
    DeleteSingleProperty(building?.id)
      .then((data) => {
        // console.log(data);
        if (data.message === "successful") {
          setDeleted(true);
          handleCloseDelete();
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const formData = new FormData(); //Create an instance of FormData // Loop through the files array and append to formData

    for (let i = 0; i < f_files.length; i++) {
      formData.append("files", f_files[i]);
    }
    formData.append("banner", f_banner);
    formData.append("propertyDoc", propertyDoc);

    formData.append("address", f_location);
    formData.append("name", f_name);
    formData.append("title", f_title);
    formData.append("price", f_price);
    formData.append("userId", building.userId);
    formData.append("category", f_category);
    formData.append("description", f_description);
    formData.append("city", f_city);
    formData.append("state", f_state);
    formData.append("commision", f_commission);

    EditSingleProperty(building?.id, formData)
      .then((data) => {
        if (data.message === "successful") {
          setSuccess(data.message);
          setPending(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        setSuccess("failed");
        setPending(false);
      });
  };

  // create a preview as a side effect, whenever selected file is changed
  const [viewFiles, setViewFiles] = useState();
  let viewFiles1 = [];

  useEffect(() => {
    if (!f_files) {
      setViewFiles(null);
      return;
    } else {
      if (
        typeof f_files[0]?.url === "string" ||
        typeof f_files[1]?.url === "string"
      ) {
        setViewFiles(f_files[0]?.url || f_files[1]?.url);
        // setViewFiles1({
        //   ...viewFiles1,
        //   url: f_files[0]?.url || f_files[1]?.url,
        // });
        viewFiles1.push(f_files[0]?.url || f_files[1]?.url);
        // console.log(f_files[0]?.url || f_files[1]?.url, "lll");

        return;
      } else {
        if (f_files[0]) {
          const objectUrl = URL.createObjectURL(f_files[0] || f_files[1]);
          setViewFiles(objectUrl);
          viewFiles1.push(objectUrl);
          // console.log(objectUrl, "ks");
          const looped = Object.keys(f_files).map((el) => {
            return URL.createObjectURL(f_files[el]);
          });
          setAllNewFile([...looped]);
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl);
        }
        return;
      }
    }
  }, [f_files]);

  useEffect(() => {
    setAllfiles([...building?.images]);
  }, [f_files]);
  // create a preview as a side effect, whenever selected file is changed
  const [viewBanner, setViewBanner] = useState();
  const [viewDoc, setViewDoc] = useState();


  
  useEffect(() => {
    if (!f_banner) {
      setViewBanner(undefined);
      return;
    }
    if (typeof f_banner === "string") {
      setViewBanner(f_banner);
    } else {
      const objectUrl = URL.createObjectURL(f_banner);
      setViewBanner(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [f_banner]);


  // useEffect(() => {
  //   if (!propertyDoc) {
  //     setViewDoc(undefined);
  //     return;
  //   }
  //   if (typeof propertyDoc === "string") {
  //     setViewDoc(propertyDoc);
  //   } else {
  //     const objectUrl = URL.createObjectURL(propertyDoc);
  //     setViewDoc(objectUrl);

  //     // free memory when ever this component is unmounted
  //     return () => URL.revokeObjectURL(objectUrl);
  //   }
  // }, [propertyDoc]);

  const imageRef = useRef(null);
  const bannerRef = useRef(null);
  const docRef = useRef(null);


  const openImageSelectDialog = () => {
    imageRef.current.click();
  };

  const openBannerSelectDialog = () => {
    bannerRef.current.click();
  };
  const openDocSelectDialog = () => {
    docRef.current.click();
  };
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  let pName = building.name;
  let newPName = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  let chars = [f_category, "Apartments", "Vacation home", "Land", "Commercial"];

  let uniqueChars = [];

  chars.forEach((c) => {
    if (!uniqueChars.includes(c)) {
      uniqueChars.push(c);
    }
  });
  const removeSelected = async (id, pId, newSelect, index) => {
    if (newSelect) {
      setAllNewFile((prev) => [...prev.filter((el, i) => !(i === id))]);
      let newFileList = Array.from(f_files);
      newFileList.splice(id, 1);
      setForm((prev) => ({
        ...prev,
        f_files: newFileList,
      }));
    } else {
      const Delete = await axios.delete(
        `${BASE_URL}/api/admin/properties/${pId}/images/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(Delete.data.success, "ish");

      if (Delete.data.success) {
        setAllfiles((prev) => [...prev.filter((el, i) => i !== index)]);
      }

      // setAllfiles((prev) => [...prev.filter((el, i) => i !== id)]);
    }
  };
  console.log(propertyDoc?.name,'propertyDoc')
  return (
    <>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          position: "relative",
          flex: "0.8",

          width: "100%",
          margin: "10px",
          display: deleted ? "none" : "block",
        }}
        className="propertyCard"
      >
        <Card
          aria-owns={deleteProperty ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onClick={() => {
            setShowBuilding(true);
            setPrevBuilding(building);
          }}
          style={{ height: "380px", boxShadow: "none", borderRadius: "8px" }}
        >
          <CardActionArea>
            {building?.images && (
              <CardMedia
                component="img"
                height="220"
                image={building?.images[0]?.url || building?.images[1]?.url}
                alt={building?.title}
              />
            )}
            <CardContent style={{ height: "120px" }}>
              <div style={{ height: "100px" }}>
                <div className="proName">
                  <Typography
                    gutterBottom
                    variant="h4"
                    style={{
                      marginBottom: "5px",
                      fontSize: "18px",
                      fontWeight: 400,
                    }}
                  >
                    {newPName(pName, 55)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className={classes.address}
                  >
                    {newPName(building?.address, 35)}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  height: "20px",
                }}
              >
               {building?.name == "Lekki Town Square Mall" ? ( 
                 <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    marginBottom: "-11px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  
                  ₦1,350,000 per sqm 
                </Typography>
                ):
                (                 <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    marginBottom: "-11px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  
                  {prettyMoney(prettyDollarConfig, building?.price)}
                </Typography>
)
                }
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
        {popEditDelete && (
          <Popover>
            <EditDelBtn onClick={handleOpenEdit}>
              <IconEdit /> Edit property
            </EditDelBtn>
            <EditDelBtn className="delete" onClick={handleOpenDelete}>
              <IconTrash /> Delete property
            </EditDelBtn>
          </Popover>
        )}
      </div>
      {/* delete modal */}
      <DeleteModals
        handleDelete={handleDelete}
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
      {/* ============================================================ */}
      {/* Add new property */}
      {/* ============================================================ */}
      <Drawer
        anchor="right"
        open={editProperty}
        sx={{ width: "450px" }}
        onClose={handleCloseEdit}
      >
        <DrawerHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1>Edit {building?.name}</h1>
              <p className="subText">Update information about this property.</p>
            </div>

            <div className="exit" onClick={handleCloseEdit}>
              <Cancel style={{ fontSize: "30px" }} />
            </div>
          </div>
        </DrawerHeader>
        <form onSubmit={handleSubmit}>
          <DrawerBody>
            <div className="input-group">
              <label>Property name</label>
              <div className="row1">
                <input
                  type="text"
                  required
                  placeholder="Enter the property name"
                  defaultValue={f_name}
                  onChange={(e) => setForm({ ...form, f_name: e.target.value })}
                />
              </div>
            </div>

            <div className="input-group">
              <label>Category</label>
              <div className="row1">
                <select
                  required
                  onChange={(e) =>
                    setForm({ ...form, f_category: e.target.value })
                  }
                >
                  {uniqueChars.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="input-group">
              <label>Title document</label>
              <div className="row1">
                <input
                  type="text"
                  required
                  placeholder="Enter a title document name"
                  defaultValue={f_title}
                  onChange={(e) =>
                    setForm({ ...form, f_title: e.target.value })
                  }
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {allFiles &&
                [...allFiles].map((item, i) => (
                  <ImageCont key={i}>
                    <IconCancel
                      className="exit"
                      onClick={() =>
                        removeSelected(item.id, item.propertyId, null, i)
                      }
                    >
                      <Cancel style={{ fontSize: "20px" }} />
                    </IconCancel>
                    <img
                      src={item?.url}
                      style={{ width: "100%", margin: "1% 1%" }}
                    />
                  </ImageCont>
                ))}
              {allNewFiles &&
                [...allNewFiles].map((item, i) => (
                  <ImageCont key={i}>
                    <IconCancel
                      className="exit"
                      onClick={() => removeSelected(i, null, true)}
                    >
                      <Cancel style={{ fontSize: "20px" }} />
                    </IconCancel>
                    <img
                      src={item}
                      style={{ width: "100%", margin: "1% 1%" }}
                    />
                  </ImageCont>
                ))}
            </div>
            <div className="input-group">
              <label>Upload</label>
              <div className="rowUpload" onClick={openImageSelectDialog}>
                <div className="col">
                  <div className="imgPreview">
                    {viewFiles ? (
                      <img
                        src={viewFiles}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span>+</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label className="properLabel">Property Image*</label>
                  <p className="uploadText">
                    Upload the image for this property
                  </p>
                  <input
                    className="upload_input"
                    type="file"
                    accept="image/*"
                    multiple
                    ref={imageRef}
                    onChange={(e) => {
                      e.preventDefault();
                      setForm({ ...form, f_files: e.target.files });
                    }}
                  />
                  <div className="button">
                    <span>+</span> Select File
                  </div>
                </div>
              </div>
            </div>

            <div className="input-group">
              <div className="rowUpload" onClick={openBannerSelectDialog}>
                <div className="col">
                  <div className="imgPreview">
                    {viewBanner ? (
                      <img
                        src={
                          viewBanner == "null"
                            ? "https://via.placeholder.com/150"
                            : viewBanner
                        }
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span>+</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label className="properLabel">Property Banner*</label>
                  <p className="uploadText">
                    Upload the banner for this property
                  </p>
                  <input
                    className="upload_input"
                    type="file"
                    accept="image/*"
                    ref={bannerRef}
                    onChange={(e) =>
                      setForm({ ...form, f_banner: e.target.files[0] })
                    }
                  />
                  <div className="button">
                    <span>+</span> Select File
                  </div>
                </div>
                
              </div>
            </div>
            <div className="input-group">
              <div className="rowUpload" onClick={openDocSelectDialog}>
                <div className="col">
                  <div className="imgPreview">
                    {propertyDoc?.name ? (
                      <img
                      src={
                        null == "null"
                          ? "https://via.placeholder.com/150"
                          : "https://via.placeholder.com/150"
                      }
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      />
                    ) : (
                      <span>+</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label className="properLabel">Property Document*</label>
                  <p className="uploadText">
                    Upload the document for this property
                  </p>
                  <br></br>
                  {propertyDoc?.name}
                  <input
                    className="upload_input"
                    type="file"
                    accept="pdf/*"
                    ref={docRef}
                    onChange={(e) => {
                      e.preventDefault();
                      setForm({ ...form, propertyDoc: e.target.files[0] })
                    }}
                  />
                  <div className="buttonN">
                    <span>+</span> Select File
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
          <DrawerBody>
            <div className="input-group">
              <label>Location</label>
              <div className="row1">
                <input
                  type="text"
                  required
                  placeholder="Enter property location"
                  defaultValue={f_location}
                  onChange={(e) =>
                    setForm({ ...form, f_location: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="input-group">
              <div className="row4">
                <div className="col">
                  <label>State</label>
                  <input
                    type="text"
                    required
                    placeholder="Enter State"
                    defaultValue={f_state}
                    onChange={(e) =>
                      setForm({ ...form, f_state: e.target.value })
                    }
                  />
                </div>
                <div className="col">
                  <label>City</label>
                  <input
                    type="text"
                    required
                    placeholder="Enter City"
                    defaultValue={f_city}
                    onChange={(e) =>
                      setForm({ ...form, f_city: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>Description</label>
              <div className="row1">
                <textarea
                  rows="10"
                  required
                  placeholder="Enter description of property"
                  defaultValue={f_description}
                  onChange={(e) =>
                    setForm({ ...form, f_description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <div className="input-group">
              <label>Price</label>
              <div className="row1">
                {/* <input
                  type="text"
                  required
                  placeholder="Enter property price"
                  defaultValue={f_price}
                  onChange={(e) =>
                    setForm({ ...form, f_price: e.target.value })
                  }
                /> */}
                <NumberFormat
                  name={f_price}
                  value={f_price}
                  defaultValue={f_price}
                  displayType={"input"}
                  placeholder="Enter property price"
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalSeparator="."
                  allowNegative={true}
                  // renderText={(value, props) => <div {...props}>{value}</div>}
                  // onValueChange={(e) =>
                  //   setForm({ ...form, f_price: e.target.value })
                  // }
                  onValueChange={(values) => {
                    const { value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setForm({ ...form, f_price: value });
                  }}
                />
              </div>
            </div>

            <div className="input-group">
            <label>Commission</label>
              <div className="row1">
                {/* <input
                  type="text"
                  required
                  placeholder="Enter property price"
                  defaultValue={f_price}
                  onChange={(e) =>
                    setForm({ ...form, f_price: e.target.value })
                  }
                /> */}
                 <input
                    type="text"
                    required
                    placeholder="Enter Commission"
                    defaultValue={f_commission}
                    onChange={(e) =>
                      setForm({ ...form, f_commission: e.target.value })
                    }
                  />
              </div>
            </div>


            <div className="submit">
              {success &&
                (success === "successful" ? (
                  <p
                    style={{ fontSize: "12px", color: "green", margin: "10px" }}
                  >
                    Edit Property: {success}
                  </p>
                ) : (
                  <p style={{ fontSize: "12px", color: "red", margin: "10px" }}>
                    Edit Property: {success}
                  </p>
                ))}
              {pending ? (
                <p
                  style={{
                    fontSize: "1.5rem",
                    background: "#ccc",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  Loading . . .
                </p>
              ) : (
                <button>Submit</button>
              )}
            </div>
          </DrawerFooter>
        </form>
      </Drawer>
    </>
  );
};

export default DashboardBuildingCard;

// const ModalFooter = styled.div`
//   border-top: 1px solid #f5f5f5;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px;

//   button {
//     background: #f5f5f5;
//     padding: 12px;
//     border: none;
//     outline: none;
//     flex: 1;
//     margin: 0px 5px;
//     cursor: pointer;
//     border-radius: 8px;

//     &.confirm {
//       background: #ff385c;
//       color: #fff;
//     }
//   }
// `;
const ImageCont = styled.div`
  position: relative;
  width: 20%;
`;
const IconCancel = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
`;
const Popover = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  background: #fff;
  border-radius: 7px;
  z-index: 1000;
  box-shadow: 2px 2px 2px #cccccc1a;
`;

const EditDelBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px;
  display: block;
  font-size: 15px;

  &.delete {
    color: #ff385c;
  }
`;

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #0404061a;
`;

const DrawerBody = styled.div`
  padding: 0px 30px;
  border-bottom: 1px solid #0404061a;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .rowUpload {
    display: flex;
    flex-direction: row;
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .imgPreview {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f2f2f2;
        border-radius: 5px;
        margin-right: 10px;

        span {
          color: #7d7d7e;
          font-size: 25px;
        }
      }
      .upload_input {
        opacity: 0;
        position: absolute;
        z-index: -1000;
      }
      .button {
        border: 1px solid #ccc;
        padding: 8px 10px;
        box-sizing: border-box;
        border-radius: 2px;
        background: transparent;
        margin: 10px 0px;
        cursor: pointer;
        color: black;
        font-size: 14px;

        span {
          color: #ff385c;
        }
      }
    }
  }

  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        witdth: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }
`;

const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;
