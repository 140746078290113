import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import styled from "@emotion/styled";

import MiniSearchBar from "components/layouts/MiniSearchBar";
import EmptyTable from "components/utils/table-layouts/EmptyTable";
import { ReferralRankingRows } from "helpers/datasets/table-rows";
import { IconExport, IconFilter } from "../../assets/icons";
import RegularHeader from "components/layouts/RegularHeader";
import ReferralRankingTable from "components/layouts/ReferralRankingTable";

const inflowRows = ReferralRankingRows;

function ReferralRanking() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <ReferralsStyles>
      <RegularHeader
        title="Referral Ranking"
        subTitle="This show realtors ranking according to the number of their referrals"
      />
      <hr />
      <div className="search-filter-export">
        <div className="inputSearch">
          <MiniSearchBar
            name={searchQuery}
            color="#fff"
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search client, name or property..."
            className="longSearch"
          />
        </div>
        <div className="filter-export">
          <div className="filter">
            <IconFilter /> <h3>Filters</h3>
          </div>
          <div className="export">
            <IconExport />
            {/* <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                color: "gray",
              }}
            >
              
            </CSVLink> */}
            <h3 style={{ color: "black" }}>Export</h3>
          </div>
        </div>
      </div>
      <hr />

      <div className="table">
        <Grid container>
          {inflowRows.length === 0 ? <ReferralRankingTable /> : <EmptyTable />}
        </Grid>
      </div>
    </ReferralsStyles>
  );
}

export default ReferralRanking;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  .table {
    margin: 20px 40px;

    @media (max-width: 640px) {
      margin: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 125vh;
    }
  }

  .search-filter-export {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;