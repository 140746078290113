import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { InflowHeadCells } from "../../helpers/datasets/table-header-data";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { IconEdit, IconTrash } from "assets/icons";
import { Drawer, CircularProgress } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import DeleteModals from "components/utils/DeleteModals";
import {
  getUserByReferralCode,
  getProperties,
  editACommission,
  addProperties,
} from "services/properties";
import {
  getASale,
  getAllSalesProperties,
  deleteASale,
} from "services/properties";
import prettyMoney from "pretty-money";
import { parseDate } from "components/utils/Time";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const InflowTable = ({
  state,
  setState,
  state2,
  setState2,
  newTrnx,
  setType,
  setNewTrnx,
}) => {
  const classes = useStyles();
  console.log(state);
  const [values, setValues] = useState([]);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setValues(state);
  }, [state]);
  useEffect(() => {
    (async () => {
      const { properties } = await getProperties();
      setProperties(properties.properties);
    })();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // console.log(values, "inflow");
  const handleCloseDelete = () => setDeleteProperty(false);

  const [id, setId] = useState("");
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [addPay, setAddPay] = useState(false);
  const handleNewPayment = () => {
    // const x = {
    //   clientId: "",
    //   fullname: "",
    //   payment_for: "",
    //   no_of_unit: "",
    //   payment_plan: "",
    //   cost_per_unit: "",
    //   mobile: "",
    //   total_amount: "",
    //   amount_just_paid: "",
    //   date_of_payment: "",
    //   pass_word: "",
    // };
    setAddPay(true);
  };
  const {
    password,
    dateOfPayment,
    amountJustPaid,
    balance,
    lastPaid,
    totalPaid,
    cost,
    unit,
    phone,
    propertyName,
    clientId,
    date,
    fullname,
  } = state;
  const handleOpenDelete = (id) => {
    setDeleteProperty(true);
    setId(id);
  };
  const handleDelete = useCallback(async () => {
    await deleteASale(id);
    const data = await getAllSalesProperties();
    handleCloseDelete();
    setState(data.transactions.payments);
  }, [id, setState]);

  const getClient = useCallback(
    async (id) => {
      setType("edit");
      setNewTrnx(true);
      const data = await getASale(id);

      const {
        ammountJustPaid,
        clientId,
        costPerUnit,
        dateOfPayment,
        fullName,
        id: ID,
        mobile,
        numberOfunits,
        paymentPlan,
        totalAmmount,
      } = data.result;
      const news = {
        client_id: clientId,
        full_name: fullName,
        payment_for: "",
        id: ID,
        no_of_unit: numberOfunits,
        payment_plan: paymentPlan,
        cost_per_unit: costPerUnit,
        mobile,
        total_amount: totalAmmount,
        amount_just_paid: ammountJustPaid,
        date_of_payment: parseDate(dateOfPayment),
        pass_word: "",
      };
      setState2(news);
    },
    [setNewTrnx, setState2, setType]
  );
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  return (
    <>
      <EnhancedTable
        headCells={InflowHeadCells}
        rows={values}
        page={page}
        paginationLabel="Showing"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {values
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            const {
              id,
              fullName,
              mobile,
              propertyName,
              totalAmmount,
              ammountJustPaid,
              clientId,
              clientReferrId,
            } = row;

            let x = propertyName ? propertyName.slice(0, 30) : "";
            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell className={classes.tableCell}>
                  <input type="checkbox" />
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {fullName ? `${fullName} ` : "No name"}
                </TableCell>
                <TableCell className={classes.tableCell}>{mobile}</TableCell>
                <TableCell className={classes.tableCell}>{x}</TableCell>
               

                <TableCell className={classes.tableCell}>{clientId}</TableCell>
                <TableCell className={classes.tableCell}>
                  {clientReferrId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prettyMoney(prettyDollarConfig, totalAmmount)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prettyMoney(prettyDollarConfig, ammountJustPaid)}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <div className="PopButtons">
                    <NewReferralButtonStyles onClick={handleNewPayment}>
                      Add up payments
                    </NewReferralButtonStyles>
                  </div>
                </TableCell>
                {/* <TableCell className={classes.tableCell}>
                  <div
                    style={{
                      flex: "0.8",

                      width: "95%",
                      margin: "-10px",
                      display: "flex",
                    }}
                  >
                    <EditDelBtn
                      onClick={() => getClient(id)}
                      style={{ cursor: "pointer" }}
                    >
                      <IconEdit /> Edit Client
                    </EditDelBtn>
                    <EditDelBtn
                      className="delete"
                      onClick={() => handleOpenDelete(id)}
                    >
                      <IconTrash /> Delete Client
                    </EditDelBtn>{" "}
                  </div>
                </TableCell> */}
              </TableRow>
            );
          })}
      </EnhancedTable>
      <DeleteModals
        handleDelete={handleDelete}
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
      {document.location.pathname === "/inflow" ? (
        <DrawerDiv>
          <Drawer
            anchor="right"
            open={addPay}
            sx={{ minWidth: "450px" }}
            onClose={() => setAddPay(false)}
            className="drawer"
          >
            <DrawerHeader>
              <div>
                <h1>Add up payments</h1>
                <p className="subText">Reconcile past payment with new ones</p>
              </div>
              <div className="exit" onClick={() => setAddPay(false)}>
                <Cancel style={{ fontSize: "30px" }} />
              </div>
            </DrawerHeader>
            <form>
              <DrawerFooter>
                <div className="input-group">
                  <label>Client ID</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="clientId"

                      // value={clientId}
                    />
                    <button type="button">Check</button>
                  </div>
                </div>

                <div className="input-group">
                  <div className="row4">
                    <div className="col">
                      <label>Full name</label>
                      <input
                        disabled
                        type="text"
                        name="fullname"
                        // value={fullname}
                      />
                    </div>
                    <div className="col">
                      <label>First pay date</label>
                      <input
                        type="date"
                        name="date"
                        // value={date}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Payment for</label>
                  <div className="row1">
                    <select
                      // value={propertyName}
                      name="propertyName"
                    >
                      <option value="">Select Property</option>
                      {properties?.map((property) => {
                        return (
                          <option value={property.name}>{property.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="input-group">
                  <label>Phone Number</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="phone"

                      // value={phone}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="row4">
                    <div className="col">
                      <label>Number of Unit(s)</label>
                      <input
                        type="text"
                        name="unit"
                        // value={unit}
                      />
                    </div>
                    <div className="col">
                      <label>Unit cost</label>
                      <input
                        type="text"
                        name="cost"
                        // value={cost}
                      />
                    </div>
                    <div className="col">
                      <label>Total amount paid</label>
                      <input
                        type="text"
                        name="totalPaid"
                        // value={totalPaid}
                      />
                    </div>
                    <div className="col">
                      <label>Last amount paid</label>
                      <input
                        type="text"
                        name="lastPaid"

                        // value={lastPaid}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Balance</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="balance"
                      // value={balance}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="row4">
                    <div className="col">
                      <label>Amount just paid</label>
                      <input
                        type="text"
                        name="amountJustPaid"
                        // value={amountJustPaid}
                      />
                    </div>
                    <div className="col">
                      <label>Date of Payment</label>
                      <input
                        type="date"
                        // value={dateOfPayment}
                        name="dateOfPayment"
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Password</label>
                  <div className="row1">
                    <input
                      type="password"
                      name="password"
                      // value={password}

                      placeholder="Enter account password"
                    />
                  </div>
                </div>

                <div className="submit">
                  <button>Submit</button>
                </div>
              </DrawerFooter>
            </form>
          </Drawer>
        </DrawerDiv>
      ) : null}
    </>
  );
};

export default InflowTable;

const EditDelBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px;
  display: block;
  font-size: 15px;

  &.delete {
    color: #ff385c;
  }
`;

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0404061a;
`;

const DrawerBody = styled.div`
  padding: 0px 30px;
  border-bottom: 1px solid #0404061a;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }
  svg {
    width: 20;
  }
`;

const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;

const DrawerDiv = styled.div`
  .drawer {
    @media (max-width: 500px) {
      width: 100% !important;
    }
  }
`;

const InflowHeaderStyles = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
  }

  .tabs {
    margin-left: 20px;
    background: #eaeaea;
    display: flex;
    padding: 5px;
    border-radius: 6px;

    a {
      padding: 8px 15px;
      border-radius: 6px;
      text-decoration: none;
      color: #000;
      font-size: 12px;
    }
    .active {
      background: #fff;
    }
  }

  .PopButtons {
    display: flex;
    @media (max-width: 800px) {
      margin-top: 30px;
    }
  }
`;
const NewReferralButtonStyles = styled.button`
  border-radius: 8px;
  background-color: #ff385c;
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  margin-right: 15px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  svg {
    margin-top: 9px;
    margin-left: 10px;
  }
  h5 {
    color: white;
  }
`;
