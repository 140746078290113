import { React } from "react";
import PropTypes from "prop-types";
import { Grid, Menu, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  menu: {
    maxHeight: "35rem !important",

    "& .MuiMenu-list": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },

    "&::-webkit-scrollbar": {
      width: ".85rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 14px rgba(0, 0, 0, 0.2)",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: ".5rem",
      background: theme.palette.common.lightGrey,

      "&:active": {
        background: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  icon: {
    "&.MuiSvgIcon-root": {
      cursor: "pointer",

      "&:hover": {
        color: theme.palette.common.lightGrey,
      },
    },
  },
}));

const HeaderNotification = ({ anchorEl, setAnchorEl }) => {
  const classes = useStyles();

  const open = Boolean(anchorEl);

  // const displayInitials = (name) => {
  //   const splitName = name.split(" ");
  //   const result = splitName[0].charAt(0) + splitName[1].charAt(0);
  //   return result;
  // };

  // const [isUnread, setisUnread] = useState(true);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      classes={{ paper: classes.menu }}
    >
      <Grid
        container
        justifyContent="space-between"
        style={{ padding: "14px 1.5rem 14px" }}
      >
        <Grid item>
          <Typography variant="body1">Notification</Typography>
        </Grid>
        <Grid item>
          <CloseIcon
            className={classes.icon}
            onClick={() => setAnchorEl(null)}
          />
        </Grid>
      </Grid>
      {/* {Array(10)
        .fill()
        .map((val, i) => (
          <MenuItem
            key={i}
            onClick={() => setAnchorEl(null)}
            className={classes.menuItem}
            disableRipple
          >
            <div
              isUnread={() => setisUnread(true)}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                {isUnread ? (
                  <div
                    style={{
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      background: "green",
                      position: "absolute",
                    }}
                  />
                ) : null}
                <Avatar
                  alt="Display avatar"
                  sx={{ bgcolor: theme.palette.secondary.main }}
                >
                  {displayInitials("Raphael Igbinedion")}
                </Avatar>
              </div>
              <Typography style={{ marginLeft: 5 }} variant="body2">
                Raphael Igbinedion{" "}
                <span style={{ color: theme.palette.common.grey }}>
                  accepted your referral request
                </span>
              </Typography>
            </div>
            <Typography variant="body2" color="secondary">
              Just now
            </Typography>
          </MenuItem>
        ))} */}
      <p
        style={{ margin: "34% 3%", whiteSpace: "normal", textAlign: "center" }}
      >
        No available notifications
      </p>
    </Menu>
  );
};

HeaderNotification.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
};

export default HeaderNotification;
