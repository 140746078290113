import React, { useState } from "react";

import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function MLMTextField({
  name,
  labelText,
  labelColor,
  backgroundColor,
  hintText,
  hintTextColor,
  fieldValue,
  inputTextColor,
  width,
  inputType,
  onChange,
  borderColor,
  isDisabled,
  divBorder,
  ...rest
}) {
  const [showText, setShowText] = useState(false);

  const toggleVisibility = () => {
    setShowText(!showText);
  };

  return (
    <InputStyles
      labelColor={labelColor}
      inputTextColor={inputTextColor}
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      hintTextColor={hintTextColor}
    >
      <h6>{labelText}</h6>
      {inputType !== "password" ? (
        <div className="input-container" style={{ border: divBorder }}>
          <input
            name={name}
            type={inputType}
            placeholder={hintText}
            value={fieldValue}
            onChange={onChange}
            disabled={isDisabled}
            {...rest}
          />
        </div>
      ) : (
        <div className="input-container-pwd" style={{ border: divBorder }}>
          <input
            name={name}
            type={showText ? "text" : "password"}
            placeholder={hintText}
            value={fieldValue}
            onChange={onChange}
            disabled={isDisabled}
          />
          <div className="toggle" onClick={toggleVisibility} {...rest}>
            {showText ? (
              <AiOutlineEyeInvisible size={30} />
            ) : (
              <AiOutlineEye size={30} />
            )}
          </div>
        </div>
      )}
    </InputStyles>
  );
}

MLMTextField.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  fieldValue: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
};

MLMTextField.defaultPropTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  fieldValue: PropTypes.string,
  inputType: "text",
  onChange: PropTypes.func,
};

export default MLMTextField;

const InputStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h6 {
    color: ${(props) => (props.labelColor ? props.labelColor : "var(--white)")};
    font-size: 15px;
    font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
  }

  input[type="date"] {
    cursor: pointer;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 15px;
    margin-left: 1px;
    margin-top: -5px;

    &:hover {
      cursor: pointer;
    }
  }

  .input-container {
    border: 1px solid A2A2A2;
    opacity: ${(props) => (props.borderColor ? 0.1 : 1)};
    border-radius: 8px;
    width: ${(props) => (props.width ? props.width : "100%")};
    box-sizing: border-box;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "transparent"};
    color: ${(props) =>
      props.inputTextColor ? props.inputTextColor : "var(--white)"};
    height: 42px;
    margin-top: 1rem;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 45px;
    left: 100px;
    top: 281px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    opacity: 0.24;
    border-radius: 8px;

    input {
      width: 100%;
      color: white;
      border: 1px solid #a2a2a2;
      padding: 12px 15px;
      margin-left: -15px;
      margin-top: -9px;
      border-radius: 9px;
      background-color: transparent;
      align-content: center;
      font-size: 14px;
      width: 400px;
      height: 45px;
      left: 100px;
      top: 281px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 8px;

      ::placeholder {
        color: A2A2A2;
        font-size: 15px;
      }
    }
  }

  .input-container-pwd {
    border: 1px solid A2A2A2;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    margin-top: 1rem;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    width: 400px;
    height: 45px;
  
    border: 1px solid #ffffff;
    box-sizing: border-box;
    opacity: 0.24;
    border-radius: 8px;

    svg {
      height: 25px;
      padding-right: 7px;
      padding-top: 3px;
      fill: #a2a2a2;
    }

    input {
      width: 100%;
      color: white;
      border: 1px solid #a2a2a2;
      padding: 12px 15px;
      margin-left: -15px;
      margin-top: -9px;
      border-radius: 9px;
      background-color: transparent;
      align-content: center;
      font-size: 14px;
      width: 400px;
      height: 45px;
      
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 8px;

      ::placeholder {
        color: #a2a2a2;
        font-size: 15px;
      }
    }

    .toggle {
      position: absolute;
      right: 5px;
    }
  }
`;
