import { useState } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { PayoutHeadCells } from "../../helpers/datasets/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { PayoutRows } from "helpers/datasets/table-rows";
import styled from "@emotion/styled";
import { IconAngleRight } from "assets/icons";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const PayoutTable = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  return (
    <EnhancedTable
      headCells={PayoutHeadCells}
      rows={PayoutRows}
      page={page}
      paginationLabel="Showing"
      hasCheckbox={false}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      {PayoutRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

          return (
            <TableRow hover tabIndex={-1} key={row.id}>
              <TableCell className={classes.tableCell}><input type="checkbox" /></TableCell>
              <TableCell className={classes.tableCell}>{row.date}</TableCell>
              <TableCell className={classes.tableCell}>{row.client}</TableCell>
              <TableCell className={classes.tableCell}>{row.clientId}</TableCell>
              <TableCell className={classes.tableCell}>{row.referrer}</TableCell>
              <TableCell className={classes.tableCell}>{row.referrerId}</TableCell>
              <TableCell className={classes.tableCell}>{row.property}</TableCell>
              <TableCell className={classes.tableCell}>{row.commission}</TableCell>
              <TableCell className={classes.tableCell}><GotoSign><IconAngleRight /></GotoSign></TableCell>
            </TableRow>
          );
        })}
    </EnhancedTable>
  );
};

export default PayoutTable;


const GotoSign = styled.span`
  background: #F2F2F2;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  svg {
    stroke: #000;
    color: #000;
    transform: rotate(180deg);
  }
`