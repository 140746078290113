import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { ClientsHeadCells } from "../../helpers/datasets/table-header-data";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { dateMoment } from "../utils/Time";
import { IconEdit, IconTrash } from "assets/icons";
import DeleteModals from "components/utils/DeleteModals";
import { deleteAClient, getAClients, getAllClients } from "services/properties";
const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const ClientsTable = ({
  state,
  handlePopoverOpen,
  popEditDelete,
  handlePopoverClose,
  setRegClient,
  setEditClient,
  setState,
  setEdit,
  id,
  page,
  setPage,
  setId,
}) => {
  const classes = useStyles();
  const [client, setClient] = useState([]);

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleOpenDelete = () => setDeleteProperty(true);

  useEffect(() => {
    setClient(state);
    // setPages(current_page);
  }, [client]);
  const handleDelete = async () => {
    await deleteAClient(id);
    const value = await getAllClients();
    setState(value.clients.clients);
    handleCloseDelete();
  };
  const getClient = useCallback(
    async (id) => {
      const data = await getAClients(id);
      setEditClient(data);
    },
    [setEditClient]
  );

  console.log(client, "client");

  return (
    <>
      <EnhancedTable
        headCells={ClientsHeadCells}
        rows={client}
        page={page - 1}
        paginationLabel="Showing"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {client
          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const {
              createdAt,
              mobile,
              firstName,
              lastName,
              email,
              id,
              status,
              clientId,
            } = row;
            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell className={classes.tableCell}>
                  <input type="checkbox" />
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {dateMoment(createdAt)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                >                  {firstName ? `${firstName} ${lastName}` : "No name"}
                </TableCell>
                <TableCell className={classes.tableCell}>{mobile}</TableCell>
                <TableCell className={classes.tableCell}>{email.length <= 20 ? email : `${email.slice(0, 20)}...`}</TableCell>
                <TableCell
                  className={classes.tableCell}
                  
                >
                  {clientId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {status === "pending" ? (
                    <div className="SIA">{status}</div>
                  ) : (
                    <div className="SA">{status == 'inProgress' ? 'in progress' :status}</div>
                  )}{" "}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  onDoubleClick={handlePopoverClose}
                >
                  <div
                    onClick={() => {
                      setId(id);
                      handlePopoverOpen();
                    }}
                    // onDoubleClick={handlePopoverClose}
                    style={{
                      position: "relative",
                      flex: "0.8",
                      // maxWidth: "300px",
                      // minWidth: "300px",
                      width: "95%",
                      margin: "-10px",
                      cursor: "pointer",
                      display: "flex",
                    }}
                  >
                    <EditDelBtn
                      onClick={(e) => {
                        setRegClient(true);
                        handlePopoverClose();
                        getClient(id);
                        setEdit(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <IconEdit /> 
                    </EditDelBtn>
                    <EditDelBtn className="delete" onClick={handleOpenDelete}>
                      <IconTrash />
                    </EditDelBtn>{" "}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        {popEditDelete && <Popover></Popover>}
      </EnhancedTable>

      <DeleteModals
        handleDelete={handleDelete}
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
    </>
  );
};

export default ClientsTable;

const Popover = styled.div`
  position: absolute;
  top: auto;
  right: 90px;
  background: #fff;
  border-radius: 7px;
  z-index: 1000;
  box-shadow: 2px 2px 2px #cccccc1a;
`;
const EditDelBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px;
  display: block;
  font-size: 15px;

  &.delete {
    color: #ff385c;
  }
`;
