import axios from "axios";
import { BASE_URL } from "./BASE_URL";

export const loginUser = async (userData) => {
  try {
    const { data, status } = await axios.post(
      `${BASE_URL}/api/auth/login`,
      userData
    );

    if (status === 200) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("userId", data.data.user.id);
    }

    return data;
  } catch (error) {
    console.log(error.message);
  }
};
