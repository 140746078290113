// import { Fragment } from "react";
// import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import HeaderContents from "./HeaderContents";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Header = ({ menuSlideIn }) => {
  const useStyles = makeStyles((theme) => ({
    appBar: {
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: !menuSlideIn ? "33rem" : "15rem",
      paddingRight: "2rem",
      transition: "all .25s linear",
    },
  }));

  const classes = useStyles();
  return (
    <Main className={`appbar ${classes.appBar}`} classes={{ root: classes.appBar }}>
      <HeaderContents />
    </Main>
  );
};

Header.propTypes = {
  menuSlideIn: PropTypes.bool.isRequired,
};

export default Header;

const Main = styled.div`
  width: 75%;
  top: 0px;
  display: flex;
  background-color: white;
  // position: fixed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (max-width: 1300px) {
    width: 73%;
  }
  @media (max-width: 1200px) {
    width: 68%;
  }
  @media (max-width: 1010px) {
    width: 63%;
  }
  @media (max-width: 800px) {
    width: 93%;
    padding-left: 60px !important;
  }
  // @media (max-width: 640px) {
  //   width: 100%;
  // }
`;
