import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { makeStyles, useTheme } from "@mui/styles";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HeaderNotification from "./HeaderNotification";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeaderProfileOptions from "./HeaderProfileOptions";
import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  role: {
    fontSize: "1.5rem",
    color: theme.palette.common.grey,
  },

  name: {
    fontWeight: "normal",
  },
  notification: {
    fontSize: "2rem",
  },
}));

const HeaderProfile = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      handleLogout();
      document.location.assign("/");
    } else {
      if (localStorage.getItem("user")) {
        if (!user) {
          setUser(JSON.parse(localStorage.getItem("user")));
        }
      } else {
        handleLogout();
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    setUser(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openOptions, setOpenOptions] = useState(null);

  const open = Boolean(openOptions);

  const classes = useStyles();
  const theme = useTheme();

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  const displayInitials = (name) => {
    const splitName = name.split(" ");
    const result = splitName[0].charAt(0) + splitName[1].charAt(0);
    return result;
  };

  return (
    <Header>
      <Grid container alignItems="center">
        <Grid item style={{ marginRight: "2rem" }}>
          <IconButton
            aria-label={notificationsLabel(0)}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <Badge badgeContent={0} color="error">
              <NotificationsActiveIcon color="primary" fontSize="large" />
            </Badge>
          </IconButton>
          <HeaderNotification anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </Grid>
        <Grid item>
          <Avatar
            alt="Display avatar"
            sx={{ bgcolor: theme.palette.secondary.main }}
          >
            {displayInitials(
              user
                ? `${user.firstName || user.email} ${
                    user.lastName || user.email
                  }`
                : "Admin User"
            )}
          </Avatar>
        </Grid>
        <Grid item style={{ marginLeft: "1em" }}>
          <Grid container alignItems="center">
            <Grid item className="name">
              <Grid container direction="column" justifyContent="center">
                <Grid item>
                  <Typography
                    variant="body1"
                    fontSize={"15px"}
                    className={classes.name}
                  >
                    {user
                      ? `${user.firstName || "Admin"} ${
                          user.lastName || "User"
                        }`
                      : "Admin User"}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    className={classes.role}
                    style={{ fontWeight: 300, textTransform: "capitalize" }}
                  >
                    {user && user.role}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="expandMoreIcon">
              <ExpandMoreIcon
                color="primary"
                fontSize="large"
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                id="dropdown-arrow"
                aria-controls="dropdown-arrow-menu"
                onClick={(event) => setOpenOptions(event.currentTarget)}
                style={{ cursor: "pointer" }}
              />
              <HeaderProfileOptions
                openOptions={openOptions}
                setOpenOptions={setOpenOptions}
                handleLogout={handleLogout}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Header>
  );
};

export default HeaderProfile;

const Header = styled.div`
  .name {
    @media (max-width: 622px) {
      display: none;
    }
  }

  .expandMoreIcon {
    @media (max-width: 622px) {
      margin-right: 30px !important;
    }
  }
`;
