import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { IconExport, IconFilter } from "../../assets/icons";

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Drawer } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { Carousel } from "react-carousel-minimal";
import { makeStyles } from "@mui/styles";
import MiniSearchBar from "components/layouts/MiniSearchBar";
import DashboardBuildingCard from "components/layouts/DashboardBuildingCard";
import { IconExport1 } from "../../assets/icons";
import { saveAs } from "file-saver";
import prettyMoney from "pretty-money";
import FormSelect from "components/utils/FormSelect";
import PropertiesHeader from "components/layouts/PropertiesHeader";
import { GetProperties, GetPropertiesS } from "services/properties";
import EmptyProperty from "components/layouts/EmptyProperty";

let PropertyDoc = "https://drive.google.com/file/d/1vuIBVSuKorxe287yA7t-VNqPP-DuHJRn/view?usp=share_link"
const useStyles = makeStyles((theme) => ({
  overviewCardWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "3rem",
    marginTop: "5rem",
    marginBottom: "5rem",
  },
  buildingCardWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  button: {
    "&.MuiButton-root": {
      background: "#fff",
      padding: "1.5rem 2rem",
      borderRadius: "5rem",
      color: theme.palette.common.dark,
      textTransform: "none",
      fontSize: "1.6rem",
    },
  },
}));
const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
// const slideNumberStyle = {
//   fontSize: "20px",
//   fontWeight: "bold",
// };

function Properties() {
  const [searchQuery, setSearchQuery] = useState("");
  const [showBuilding, setShowBuilding] = useState(false);
  const [prevBuilding, setPrevBuilding] = useState({});
  const [properties, setProperties] = useState(false);
  const [pending, setPending] = useState(false);
  const [getTotal, setGetTotal] = useState(0);
  const [deletePro, setDeletePro] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    setPending(true);
    GetProperties(searchQuery)
      .then((data) => {
        if (data.message === "successful") {
          setProperties(data.data.properties.properties);
          setGetTotal(data.data.properties.properties.length);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
  }, [deletePro]);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    GetProperties(e.target.value)
      .then((data) => {
        if (data.message === "successful") {
          setProperties(data.data.properties.properties);
          setGetTotal(data.data.properties.properties.length);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
  };
  const downloadImage = () => {
    let img = prevBuilding?.banner;
    saveAs(img, "image.jpg"); // Put your image url here.
  };
  const downloadImage1 = () => {
    let img = prevBuilding?.propertyDoc;
    saveAs(img, "document.pdf"); // Put your image url here.
  };
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  let img = [];
  if (prevBuilding.images) {
    prevBuilding.images.map((i) => {
      console.log(i.url);
      return img.push({ image: i.url, caption: "" });
    });
  }

  // if (prevBuilding.images) {
  //   // prevBuilding.images.map((i) => {
  //   //   console.log(i.url);
  //   //   img.push({ image: i.url, caption: "" });
  //   // });
  //   img.push({
  //     image: prevBuilding.images[0].url,
  //     caption: "",
  //   });
  // }
  let show = prevBuilding?.banner == "null" ? false : true;

  return (
    <>
      <ReferralsStyles>
        <PropertiesHeader
          title="Properties"
          subTitle="View all Whiterose properties"
        />

        <hr />
        <div className="search-filter-export">
          <div className="inputSearch">
            <MiniSearchBar
              name={searchQuery}
              color="#fff"
              onChange={handleChange}
              value={searchQuery}
              placeHolder="Search for property..."
              className="longSearch"
            />
          </div>
          <div className="filter-export">
            <div className="sort" style={{ marginLeft: "15%" }}>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item mr={1}>
                    <p>Sort by</p>
                  </Grid>
                  <Grid item>
                    <FormSelect
                      options={[1, 2, 3, 4, 5]}
                      style={{ height: "3.5rem", minWidth: "8rem" }}
                      placeholderText="All"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <hr />

        <Grid
          container
          direction="column"
          mt={5}
          style={{ padding: "0rem 14px" }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h3"
                style={{ fontWeight: "600" }}
                className="allPT"
              >
                Available Properties ({getTotal || 0})
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.buildingCardWrapper}>
            {pending ? (
              <Box
                sx={{
                  display: "flex",
                  height: "60vh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : properties.length > 0 ? (
              properties.map((building) => (
                <DashboardBuildingCard
                  key={building.id}
                  building={building}
                  setShowBuilding={setShowBuilding}
                  setPrevBuilding={setPrevBuilding}
                  setDeletePro={setDeletePro}
                />
              ))
            ) : (
              <EmptyProperty />
            )}
          </div>
        </Grid>
      </ReferralsStyles>

      {/* ============================================================ */}
      {/* View property */}
      {/* ============================================================ */}
      <Drawer
        anchor="right"
        open={showBuilding}
        sx={{ width: 300 }}
        onClose={() => setShowBuilding(false)}
      >
        <DrawerHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>

            <div className="exit" onClick={() => setShowBuilding(false)}>
              <Cancel style={{ fontSize: "30px" }} />
            </div>
          </div>
          <p>{prevBuilding?.category}</p>
          <h1>{prevBuilding?.name}</h1>
          <p style={{ color: "#7D7D7E" }}>{prevBuilding?.address}</p>

          {/* {prevBuilding?.images?.map(
            (img, index) =>
              img.url && (
                <img
                  style={{ width: "100%" }}
                  src={img.url}
                  key={index}
                  alt={prevBuilding?.title}
                /> 
              )
          )} */}

          {prevBuilding?.images && (
            // <img
            //   src={prevBuilding?.images[0]?.url || prevBuilding?.images[1]?.url}
            //   alt="Beach Home Villa"
            // />
            <Carousel
              data={img}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              // slideNumber={true}
              // slideNumberStyle={slideNumberStyle}
              // automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="transparent"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "auto",
                margin: "40px auto",
              }}
            />
          )}
          <p className="desc">{prevBuilding?.description}</p>

          <div className="table tableU">
            {/* <div className="row">
              <span className="title">Units Available</span>
              <span className="final">{prevBuilding?.units_available}</span>
            </div> */}
            <div className="row">
              <span className="title">City</span>
              <span className="final">{prevBuilding?.city}</span>
            </div>
            <div className="row">
              <span className="title">State</span>
              <span className="final">{prevBuilding?.state}</span>
            </div>

            {/* <div className="row">
              <span className="title">Commission</span>
              <span className="final"> {prevBuilding?.commission}%</span>
            </div> */}

            {prevBuilding?.name == "Lekki Town Square Mall" ?(
              <>
              <div className="row">
              <span className="title">                      Ground Floor
</span>
              <span className="final">
              ₦1,350,000 per sqm 
              </span>
            </div>
            <div className="row">
              <span className="title">                      First Floor
</span>
              <span className="final">
              ₦1,200,000 per sqm
              </span>
            </div>
            <div className="row">
              <span className="title">                      2nd floor
</span>
              <span className="final">
              ₦1,000,000
              </span>
            </div>
            <div className="rowN">
              <span className="title">                      Store size
</span>
              <span className="final">
              38 sqm and above
              </span>
            </div>
              </>
            ):(<div className="rowN">
              <span className="title">Price</span>
              <span className="final">
                {prettyMoney(prettyDollarConfig, prevBuilding?.price)}
              </span>
            </div>)}
          </div>
        </DrawerHeader>
        {show && (
          <DrawerFooter>
            {/* <h1>Property Banner</h1>
            <img src={prevBuilding?.banner} alt="Banner" /> */}
          <div className="submit" style={{    display: 'flex'}}>
              <button onClick={downloadImage}>
                <IconExport1 /> Download image
              </button>

              {prevBuilding?.name == "Lekki Town Square Mall" ?(<button style={{background:'black',marginLeft: '5%'}} onClick={downloadImage1}>
              <IconExport1 /> Download PDF
            </button>) :(<button style={{background:'black',marginLeft: '5%'}} >
              <IconExport1 /> Download PDF
            </button>)}

            </div>
          </DrawerFooter>
        )}
      </Drawer>
    </>
  );
}

export default Properties;

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #0404061a;

  h1 {
    margin: 10px 0px;
  }

  img {
    margin: 20px 0px;
    width: 400px;
  }

  .desc {
    width: 445px;
    line-height: 25px;
  }

  .table {
    margin-top: 10px;

    .row {
      padding: 15px 0px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;

      .title {
        color: #7d7d7e;
      }
      .final {
        font-size: 15px;
      }
    }
    .rowN {
      padding: 15px 0px;
      display: flex;
      justify-content: space-between;

      .title {
        color: #7d7d7e;
      }
      .final {
        font-size: 15px;
      }
    }
  }
`;

const DrawerFooter = styled.div`
  padding: 30px;

  img {
    margin: 20px 0px;
    width: 400px;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 155px;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        stroke: #fff;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
`;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 120vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  .sort {
    @media (max-width: 640px) {
      margin-top: 10px;
      margin-left: 20% !important;
    }
  }
  .search-filter-export {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
