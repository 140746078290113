import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import MiniSearchBar from "components/layouts/MiniSearchBar";
import EmptyTable from "components/utils/table-layouts/EmptyTable";
import { IconExport, IconFilter } from "../../assets/icons";
import RegularHeader from "components/layouts/RegularHeader";
import MemberTable from "components/layouts/MemberTable";

import { getAllMembers,getAllMembersS } from "services/properties";

import Loader from "components/Utilities/Loader";
import { CSVLink } from "react-csv";
import { memberHeaders } from "components/Utilities/csvHeader";

const Members = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const value = await getAllMembers();
      setState(value.users.users);
      setLoading(false);
      console.log(value.users.users, "mmm");
    })();
  }, []);
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    (async () => {
      setLoading(true);
      const value = await getAllMembersS(e.target.value);
      setState(value.users.users);
      setLoading(false);
      console.log(value.users.users, "mmm");
    })();
  };

  const csvReport = {
    fileName: "whiterose.csv",
    headers: memberHeaders,
    data: state,
  };

  return (
    <ReferralsStyles>
      <RegularHeader
        title="Members"
        subTitle="This displays the total people on this platform"
      />
      <hr />
      <div className="search-filter-export">
        <div className="inputSearch">
          <MiniSearchBar
            name={searchQuery}
            color="#fff"
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search for a member..."
            className="longSearch"
          />
        </div>
        <div className="filter-export">
          <div className="filter">
            <IconFilter /> <h3>Filters</h3>
          </div>
          <div className="export">
            <IconExport />
            <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                color: "gray",
              }}
            >
              <h3 style={{ color: "black" }}>Export</h3>
            </CSVLink>
          </div>
        </div>
      </div>
      <hr />

      <div className="table">
        {loading ? (
          <Loader />
        ) : (
          <Grid container>
            {state && state.length > 0 ? (
              <MemberTable state={state} setState={setState} />
            ) : (
              <EmptyTable />
            )}
          </Grid>
        )}
      </div>
    </ReferralsStyles>
  );
};

export default Members;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  .table {
    margin: 20px 40px;

    @media (max-width: 640px) {
      margin: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 125vh;
    }
  }

  .search-filter-export {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      padding: 15px 20px;
    }
  }

  hr {
    width: 100%;
    background: #0404061a;

    @media (max-width: 640px) {
      width: 113%;
    }
  }

  .inputSearch {
    margin-left: 38px;
    @media (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -38px;
    }
  }

  .filter-export {
    display: flex;

    @media (max-width: 640px) {
      margin-top: 10px;
    }

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      @media (max-width: 640px) {
        width: 45vw;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
