import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import FormInput from "components/utils/FormInput";
import HeaderProfile from "./HeaderProfile";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",

    "&.MuiToolbar-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ".MuiInputBase-input": {
      fontSize: "15px",
      fontWeight: "400",
    },
  },
}));
const HeaderContents = () => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar}>
      <FormInput placeholder="Search anything..." onChange={() => {}} />
      <HeaderProfile />
    </Toolbar>
  );
};

export default HeaderContents;
