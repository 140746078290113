import React, { useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { IconAddCircle } from "../../assets/icons";
import { Link } from "react-router-dom";
import { Drawer, CircularProgress } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { parseDate } from "components/utils/Time";
import {
  getUserByReferralCode,
  getProperties,
  editACommission,
  getAllSalesProperties,
  addProperties,
} from "services/properties";
import NumberFormat from "react-number-format";

const InflowHeader = ({
  title,
  state2,
  setState2,
  setState: set,
  subTitle,
  // state,
  type,
  newTrnx,
  setNewTrnx,
}) => {
  const [addPay, setAddPay] = useState(false);
  const handleAddUser = () => {
    const x = {
      client_id: "",
      full_name: "",
      payment_for: "",
      no_of_unit: "",
      payment_plan: 0,
      cost_per_unit: "",
      mobile: "",
      total_amount: "",
      amount_just_paid: "",
      date_of_payment: "",
      pass_word: "",
    };
    setState2(x);
    setNewTrnx(true);
  };
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    password: "",
    dateOfPayment: "",
    amountJustPaid: "",
    balance: "",
    lastPaid: "",
    totalPaid: "",
    cost: "",
    unit: "",
    phone: "",
    propertyName: "",
    clientId: "",
    date: "",
    fullname: "",
  });

  const [properties, setProperties] = useState([]);
  const [e, setE] = useState([]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setState({
        ...state,
        [name]: value,
      });
    },
    [state]
  );
  const handleChange2 = useCallback(
    (e) => {
      const { name, value } = e.target;
      setState2({
        ...state2,
        [name]: value,
      });
    },
    [state2, setState2]
  );
  const {
    password,
    dateOfPayment,
    amountJustPaid,
    balance,
    lastPaid,
    totalPaid,
    cost,
    unit,
    phone,
    propertyName,
    clientId,
    date,
    fullname,
  } = state;
  const checkClient = useCallback(async () => {
    //  =
    const { firstName, lastName } = await getUserByReferralCode(
      "clients",
      clientId
    );

    setState({
      ...state,
      fullname: `${firstName} ${lastName} `,
    });
  }, [state, clientId]);
  const handleSubmit = () => console.log(123);
  useEffect(() => {
    (async () => {
      const { properties } = await getProperties();
      setProperties(properties.properties);
    })();
  }, []);
  const {
    client_id,
    full_name,
    payment_for,
    no_of_unit,
    payment_plan,
    cost_per_unit,
    total_amount,
    mobile,
    amount_just_paid,
    date_of_payment,
    pass_word,
  } = state2;

  const findClient = useCallback(async () => {
    const { firstName, lastName , propertyName,numberOfunits,mobile} = await getUserByReferralCode(
      "clients",
      client_id
    );
    setState2({
      ...state2,
      full_name: `${firstName} ${lastName} `,
      payment_for: propertyName,
      no_of_unit: numberOfunits,
      mobile: mobile,
    });
  }, [client_id, state2, setState2]);
  const handle_submit = useCallback(
    async (e) => {
      const {
        client_id,
        id,
        full_name,
        payment_for,
        mobile,
        no_of_unit,
        payment_plan,
        cost_per_unit,
        total_amount,
        amount_just_paid,
        date_of_payment,
        pass_word,
      } = state2;

      e.preventDefault();
      setLoading(true);
      if (type === "edit") {
        try {
          await editACommission(id, {
            ammountJustPaid: amount_just_paid,
            adminPassword: pass_word,
            numberOfunits: no_of_unit,
            fullName: full_name,
            mobile,
            propertyPrice: total_amount,
            propertyName: payment_for,
            paymentPlan: payment_plan,
            costPerUnit: cost_per_unit,
            totalAmmount: total_amount,
            dateOfPayment: parseDate(date_of_payment),
          });
          setLoading(false);
          const da = await getAllSalesProperties();
          console.log(da, "from errish");

          set(da.transactions.payments);
          console.log(da, "from errish");

          setNewTrnx(false);
          setState2({
            client_id: "",
            full_name: "",
            payment_for: "",
            no_of_unit: "",
            payment_plan: "",
            cost_per_unit: "",
            mobile: "",
            total_amount: "",
            amount_just_paid: "",
            date_of_payment: "",
            pass_word: "",
          });
          window.location.reload();
        } catch (err) {
          console.log(err, "from errish");
          setE(err.error.userMessage);
        }
      } else {
        try {
          await addProperties({
            ammountJustPaid: amount_just_paid,
            adminPassword: pass_word,
            clientId: client_id,
            numberOfunits: no_of_unit,
            fullName: full_name,
            mobile,
            propertyPrice: total_amount,
            propertyName: payment_for,
            paymentPlan: payment_plan,
            costPerUnit: cost_per_unit,
            totalAmmount: total_amount,
            dateOfPayment: date_of_payment,
          });
          setLoading(false);
          setState2({
            client_id: "",
            full_name: "",
            payment_for: "",
            no_of_unit: "",
            payment_plan: "",
            cost_per_unit: "",
            mobile: "",
            total_amount: "",
            amount_just_paid: "",
            date_of_payment: "",
            pass_word: "",
          });
          window.location.reload();
        } catch (err) {
          console.log(err, "from errish");
          setE(err.error.userMessage);
        }
      }
    },
    [state2, set, setNewTrnx, setState2, type]
  );
  const handleNewPayment = () => {
    // const x = {
    //   clientId: "",
    //   fullname: "",
    //   payment_for: "",
    //   no_of_unit: "",
    //   payment_plan: "",
    //   cost_per_unit: "",
    //   mobile: "",
    //   total_amount: "",
    //   amount_just_paid: "",
    //   date_of_payment: "",
    //   pass_word: "",
    // };
    setAddPay(true);
  };
  return (
    <>
      <InflowHeaderStyles>
        <div className="meta">
          <HeaderTab>
            <div style={{ display: "flex" }}>
              <h3>{title}</h3>
              <div className="tabs">
                {document.location.pathname === "/inflow" ? (
                  <>
                    <Link to="/inflow" className="active">
                      Sales
                    </Link>
                    <Link to="/membership">Membership</Link>
                  </>
                ) : (
                  <>
                    <Link to="/inflow">Sales</Link>
                    <Link to="/membership" className="active">
                      Membership
                    </Link>
                  </>
                )}
              </div>
            </div>

            {document.location.pathname === "/inflow" && (
              <div className="PopButtons">
                {/* <NewReferralButtonStyles onClick={handleNewPayment}>
                  Add up payments
                </NewReferralButtonStyles> */}
                <NewReferralButtonStyles onClick={handleAddUser}>
                  New Transaction <IconAddCircle />
                </NewReferralButtonStyles>
              </div>
            )}
          </HeaderTab>
          <p style={{ marginTop: "15px", fontSize: "15px", color: "#7D7D7E" }}>
            <span
              style={{ fontSize: "2rem", fontWeight: "bold", color: "#000" }}
            >
              {subTitle}
            </span>{" "}
            in{" "}
            {document.location.pathname === "/inflow" ? "sales" : "membership"}
          </p>
        </div>
      </InflowHeaderStyles>

      {/* ============================================================ */}
      {/* Add up Payment */}
      {/* ============================================================ */}

      {/* {document.location.pathname === "/inflow" ? (
        
      ) : null} */}

      {/* ======================================= */}
      {/* New Transaction */}
      {/* ======================================= */}
      {document.location.pathname === "/inflow" ? (
        <DrawerDiv>
          <Drawer
            anchor="right"
            open={newTrnx}
            sx={{ width: "450px" }}
            onClose={() => setNewTrnx(false)}
            className="drawer"
          >
            <DrawerHeader>
              <div>
                <h1>New transaction</h1>
                <p className="subText">Register a new property paymentss</p>
              </div>
              <div className="exit" onClick={() => setNewTrnx(false)}>
                <Cancel
                  style={{
                    fontSize: "30px",
                  }}
                />
              </div>
            </DrawerHeader>
            <form onSubmit={handle_submit}>
              <DrawerBody>
                <div className="input-group">
                  <label>Client ID</label>
                  <div className="row1">
                    <input
                      required
                      type="text"
                      name="client_id"
                      value={client_id}
                      onChange={handleChange2}
                    />
                    <button onClick={findClient} type="button">
                      Check
                    </button>
                  </div>
                </div>

                <div className="input-group">
                  <label>Full name</label>
                  <div className="row1">
                    <input
                      required
                      type="text"
                      onChange={handleChange2}
                      name="full_name"
                      disabled
                      value={full_name}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <label>Payment for</label>
                  <div className="row1">
                    <select
                      required
                      name="payment_for"
                      value={payment_for}
                      onChange={handleChange2}
                    >
                      <option value="">Select an Option</option>
                      {properties?.map((property) => {
                        return (
                          <option value={property.name}>{property.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="input-group">
                  <label>Number of Units</label>
                  <div className="row1">
                    <input
                      required
                      type="text"
                      name="no_of_unit" //
                      value={no_of_unit}
                      onChange={handleChange2}
                    />
                  </div>
                </div>
              </DrawerBody>
              <DrawerFooter>
                <div className="input-group">
                  <label>Payment plan</label>
                  <div className="row1">
                    <select
                      name="payment_plan"
                      required
                      value={payment_plan}
                      onChange={handleChange2}
                    >
                      <option value="">Choose a payment plan</option>
                      <option value="onetime">Full payment</option>
                      <option value="installmental">Installment payment</option>
                    </select>
                  </div>
                </div>
                <div className="input-group">
                  <label>Phone Number</label>
                  <div className="row1">
                    <input
                      type="text"
                      required
                      name="mobile"
                      onChange={handleChange2}
                      value={mobile}
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="row4">
                    <div className="col">
                      <label>Cost per unit</label>
                      <input
                        type="text"
                        required
                        name="cost_per_unit"
                        value={cost_per_unit}
                        onChange={handleChange2}
                      />
                    </div>
                    <div className="col">
                      <label>Total amount</label>
                      {/* <input
                      type="text"
                      name="total_amount"
                      value={total_amount}
                    /> */}
                      <NumberFormat
                        value={total_amount}
                        name={"total_amount"}
                        displayType={"input"}
                        placeholder="Enter Total Amount"
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalSeparator="."
                        allowNegative={true}
                        // renderText={(value, props) => <div {...props}>{value}</div>}
                        // onValueChange={(e) =>
                        //   setForm({ ...form, f_price: e.target.value })
                        // }
                        onValueChange={(values) => {
                          const { value } = values;
                          // formattedValue = $2,223
                          // value ie, 2223
                          setState2({
                            ...state2,
                            total_amount: value,
                          });
                        }}
                      />
                    </div>
                    <div className="col">
                      <label>Amount just paid</label>
                      {/* <input
                      type="text"
                      name="amount_just_paid"
                      value={amount_just_paid} //
                      onChange={handleChange2}
                    /> */}

                      <NumberFormat
                        value={amount_just_paid}
                        name={"total_amount"}
                        displayType={"input"}
                        placeholder="Enter Amount just paid"
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalSeparator="."
                        allowNegative={true}
                        // renderText={(value, props) => <div {...props}>{value}</div>}
                        // onValueChange={(e) =>
                        //   setForm({ ...form, f_price: e.target.value })
                        // }
                        onValueChange={(values) => {
                          const { value } = values;
                          // formattedValue = $2,223
                          // value ie, 2223
                          setState2({
                            ...state2,
                            amount_just_paid: value,
                          });
                        }}
                      />
                    </div>
                    <div className="col">
                      <label>Date of Payment</label>
                      <input
                        type="date"
                        required
                        name="date_of_payment"
                        value={date_of_payment}
                        onChange={handleChange2}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Password</label>
                  <div className="row1">
                    <input
                      type="password"
                      required
                      name="pass_word"
                      onChange={handleChange2}
                      value={pass_word}
                      placeholder="Enter account password"
                    />
                  </div>
                </div>
                <div>
                  <p>{e}</p>
                </div>
                <div className="submit">
                  <button type="submit" disabled={loading}>
                    {loading ? (
                      <CircularProgress
                        size={25}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </DrawerFooter>
            </form>
          </Drawer>
        </DrawerDiv>
      ) : null}
    </>
  );
};

export default React.memo(InflowHeader);

InflowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0404061a;
`;

const DrawerBody = styled.div`
  padding: 0px 30px;
  border-bottom: 1px solid #0404061a;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }
  svg {
    width: 20;
  }
`;

const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;

const DrawerDiv = styled.div`
  .drawer {
    @media (max-width: 500px) {
      width: 100% !important;
    }
  }
`;

const InflowHeaderStyles = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
  }

  .tabs {
    margin-left: 20px;
    background: #eaeaea;
    display: flex;
    padding: 5px;
    border-radius: 6px;

    a {
      padding: 8px 15px;
      border-radius: 6px;
      text-decoration: none;
      color: #000;
      font-size: 12px;
    }
    .active {
      background: #fff;
    }
  }

  .PopButtons {
    display: flex;
    @media (max-width: 800px) {
      margin-top: 30px;
    }
  }
`;
const NewReferralButtonStyles = styled.button`
  border-radius: 8px;
  background-color: #ff385c;
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  margin-right: 15px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  svg {
    margin-top: 9px;
    margin-left: 10px;
  }
  h5 {
    color: white;
  }
`;
