import React from "react";

import styled from "@emotion/styled";

import PropTypes from "prop-types";

function SettingsHeader({ title, subTitle }) {
  return (
    <>
      <OutflowHeaderStyles>
        <div className="meta">
          <HeaderTab>
            <div style={{ display: "flex" }}>
              <h3>{title}</h3>
            </div>

            <div className="PopButtons">
              <NewReferralButtonStyles>Save Settings</NewReferralButtonStyles>
            </div>
          </HeaderTab>
          <p style={{ marginTop: "15px", fontSize: "15px", color: "#7D7D7E" }}>
            {subTitle}
          </p>
        </div>
      </OutflowHeaderStyles>
    </>
  );
}

export default SettingsHeader;

SettingsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const OutflowHeaderStyles = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  .tabs {
    margin-left: 20px;
    background: #eaeaea;
    display: flex;
    padding: 5px;
    border-radius: 6px;

    a {
      padding: 8px 15px;
      border-radius: 6px;
      text-decoration: none;
      color: #000;
    }
    .active {
      background: #fff;
    }
  }

  .PopButtons {
    display: flex;
  }
`;
const NewReferralButtonStyles = styled.button`
  border-radius: 8px;
  background-color: #ff385c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-right: 38px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  svg {
    margin-top: 9px;
    margin-left: 10px;
  }
  h5 {
    color: white;
  }
`;
