import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { OutflowHeadCells } from "../../helpers/datasets/table-header-data";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dateMoment } from "../utils/Time";
import { IconEdit, IconTrash } from "assets/icons";
import DeleteModals from "components/utils/DeleteModals";

import {
  deleteAComission,
  getACommission,
  getAllClientsCommission,
  getUserByReferralCode,
} from "services/properties";
import prettyMoney from "pretty-money";

import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const OutflowTable = ({
  state,
  setType,
  popEditDelete,
  addPay,
  setAddPay,
  setEditClient,
  setState,
  setState2,
  state2,
}) => {
  const [values, setValues] = useState([]);
  const [emails, setEmail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state) {
      state.map(async (el) => {
        console.log("from el", el);

        checkClient(el.referrerId).then(async (response) => {
          console.log("from get response", response);

          if (response?.email) {
            setEmail((prev) => [
              ...prev,
              {
                id: el.id,
                firstName: response.firstName,
                lastName: response.lastName,
              },
            ]);
          } else {
            setEmail((prev) => [
              ...prev,
              {
                id: el.id,
                firstName: "no first name",
                lastName: "no last name",
              },
            ]);
          }
        });
      });
      // console.log("state", state.length, emails.length);
      // setValues(state);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log("from get response email", emails);

    if (emails.length === state.length) {
      const newData = state.map((el) => ({
        ...el,
        firstName:
          emails.filter((ele) => ele.id === el.id)[0].firstName ||
          "no firstName",
        lastName:
          emails.filter((ele) => ele.id === el.id)[0].lastName || "no lastName",
      }));
      setValues(newData);
      setLoading(false);
    }
  }, [state, emails.length]);

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rId, setRId] = useState();

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const handleOpenDelete = (id) => {
    setDeleteProperty(true);
    setId(id);
  };
  const handleDelete = useCallback(async () => {
    await deleteAComission(id);
    const data = await getAllClientsCommission();
    handleCloseDelete();
    setState(data.commissions.commissions);
  }, [id, setState]);

  let checkClient = async (id) => {
    console.log("from get check", id);

    let x = await getUserByReferralCode("users", id);

    console.log(x, "from res x");

    return x;
  };

  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  return (
    <>
      <EnhancedTable
        headCells={OutflowHeadCells}
        rows={values}
        page={page}
        paginationLabel="Showing"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {values
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            const {
              createdAt,
              id,
              clientFullName,
              clientId,
              firstName,
              lastName,
              propertyName,
              referrerId,
              commission,
            } = row;
            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell className={classes.tableCell}>
                  <input type="checkbox" /> 
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {dateMoment(createdAt)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {clientFullName}
                </TableCell>
                <TableCell className={classes.tableCell}>{clientId}</TableCell>
                <TableCell className={classes.tableCell}>
                  {`${firstName} ${lastName}`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {referrerId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                
                  {propertyName ? `${propertyName.slice(0, 30)}...` : ""}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prettyMoney(prettyDollarConfig, commission)}
                </TableCell>
              </TableRow>
            );
          })}
      </EnhancedTable>
      <DeleteModals
        handleDelete={handleDelete}
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
    </>
  );
};

export default OutflowTable;

// const StatusBtn = styled.button`
//   padding: 7px 10px;
//   border-radius: 6px;
//   border: none;
//   outline: none;
//   font-size: 13px;

//   &.ACTIVE {
//     background: #1ec6771a;
//     color: #1ec677;
//   }
//   &.INACTIVE {
//     background: #ed32371a;
//     color: #ed3237;
//   }
// `;
// const Popover = styled.div`
//   position: absolute;
//   top: auto;
//   right: 90px;
//   background: #fff;
//   border-radius: 7px;
//   z-index: 1000;
//   box-shadow: 2px 2px 2px #cccccc1a;
// `;
const EditDelBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px;
  display: block;
  font-size: 15px;

  &.delete {
    color: #ff385c;
  }
`;
