import React from "react";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";

import SettingsHeader from "components/layouts/SettingsHeader";


function Settings() {
  return (
    <ReferralsStyles>
        <SettingsHeader title="Settings" subTitle="Edit account information " />
        <div className="search-filter-export">
        </div>

        <Grid container style={{ padding: "20px 40px" }}>
            <form action="">

            </form>
        </Grid>
    </ReferralsStyles>
  );
}

export default Settings;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  .search-filter-export {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #0404061A;
    border-bottom: 1px solid #0404061A;
  }

  .filter-export {
    display: flex;

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #FFFFFF;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
