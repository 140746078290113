import React from "react";

import styled from "@emotion/styled";

import { FiSearch } from "react-icons/fi";

function MiniSearchBar({
  name,
  onChange,
  value,
  placeHolder,
  width,
  color,
  marginLeft,
}) {
  return (
    <MiniSearchBarStyles
      width={width}
      marginLeft={marginLeft}
      color={color}
      className="longSearch"
    >
      <FiSearch />
      <input
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
      />
    </MiniSearchBarStyles>
  );
}

export default MiniSearchBar;

const MiniSearchBarStyles = styled.div`
  // margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "38px")};
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 40px;
  color: gray;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: ${(props) => (props.color ? props.color : "var(--white)")};
  /* border: 1px solid var(--gray-2); */
  border: 1px solid rgba(4, 4, 6, 0.1);

  @media (max-width: 640px) {
    width: 100%;
  }

  svg {
    width: 30px;
    height: 30px;
    padding-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  input {
    width: ${(props) => (props.width ? "170px" : "320px")};
    height: 25px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding-right: 7px;
    color: var(--main-bg);
  }
`;
