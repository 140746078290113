import { useState } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { ReferrerRankingHeadCells } from "../../helpers/datasets/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { ReferralRankingRows } from "helpers/datasets/table-rows";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const ReferralRankingTable = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  return (
    <EnhancedTable
      headCells={ReferrerRankingHeadCells}
      rows={ReferralRankingRows}
      page={page}
      paginationLabel="Showing"
      hasCheckbox={false}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      {ReferralRankingRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {

          return (
            <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell className={classes.tableCell}><input type="checkbox" /></TableCell>

              <TableCell className={classes.tableCell}>{row.id + 1}</TableCell>
              <TableCell className={classes.tableCell}>{row.name}</TableCell>
              <TableCell className={classes.tableCell}>{row.phone}</TableCell>
              <TableCell className={classes.tableCell}>{row.referralId}</TableCell>
              <TableCell className={classes.tableCell}>{row.downlines}</TableCell>
            </TableRow>
          );
        })}
    </EnhancedTable>
  );
};

export default ReferralRankingTable;
