import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "components/layouts/Header";
import { theme } from "components/theme";
import SideMenu from "components/layouts/Sidemenu";
import PagesRoutes from "./routes/Routes";
import styled from "@emotion/styled";

const App = () => {
  const [menuSlideIn, setMenuSlideIn] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedDropdown, setSelectedDropdown] = useState(0);

  return (
    <ThemeProvider theme={theme}>
      <Main>
        <Router>
          <div className="header-1">
            {document.location.pathname === "/dashboard" && (
              <Header menuSlideIn={menuSlideIn} />
            )}
          </div>
          {document.location.pathname !== "/" && (
            <div>
              <SideMenu
                menuSlideIn={menuSlideIn}
                setMenuSlideIn={setMenuSlideIn}
                selectedMenu={selectedMenu}
                setSelectedMenu={setSelectedMenu}
                selectedDropdown={selectedDropdown}
                setSelectedDropdown={setSelectedDropdown}
              />
              <div className="header-2">
                <Header menuSlideIn={menuSlideIn} />
              </div>
            </div>
          )}
          <main
            style={{
              // paddingBottom: "10rem",
              paddingLeft:
                document.location.pathname === "/"
                  ? ""
                  : !menuSlideIn
                  ? "28rem"
                  : "8rem",
            }}
          >
            {/* <div className="header-2">
              <Header menuSlideIn={menuSlideIn} />
            </div> */}
            <PagesRoutes />
          </main>
        </Router>
      </Main>
    </ThemeProvider>
  );
};

export default App;

const Main = styled.div`
  main {
    @media (max-width: 1000px) {
      padding-left: 0px !important;
    }
  }

  .header-1 {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .header-2 {
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }
`;
