import React, { useState } from "react";

import styled from "@emotion/styled";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { Cancel } from "@mui/icons-material";

function PayoutHeader({ title, subTitle }) {
  const [commission, setCommission] = useState(false);

  return (
    <>
      <OutflowHeaderStyles>
        <div className="meta">
          <HeaderTab>
            <div>
              <h3>{title}</h3>
              <p
                style={{
                  marginTop: "15px",
                  fontSize: "15px",
                  color: "#7D7D7E",
                }}
              >
                {subTitle}
              </p>
            </div>

            <div className="PopButtons">
              <NewReferralButtonStyles onClick={() => setCommission(true)}>
                Process Payout
              </NewReferralButtonStyles>
            </div>
          </HeaderTab>
        </div>

        <JumbTabs>
          <div className="tabs">
            {document.location.pathname === "/payout" ? (
              <>
                <Link to="/payout" className="active">
                  Commissions
                </Link>
                <Link to="/discounted-salaries">Discounted Salaries</Link>
              </>
            ) : (
              <>
                <Link to="/payout">Commissions</Link>
                <Link to="/discounted-salaries" className="active">
                  Discounted Salaries
                </Link>
              </>
            )}
          </div>
        </JumbTabs>
      </OutflowHeaderStyles>

      {/* ============================================================ */}
      {/* Commission */}
      {/* ============================================================ */}
      <Drawer
        anchor="right"
        open={commission}
        sx={{ width: "450px" }}
        onClose={() => setCommission(false)}
      >
        <DrawerHeader>
          <div>
            <h1>Commission</h1>
            <p className="subText">List of all members’ commission</p>
          </div>
          <div className="exit" onClick={() => setCommission(false)}>
            <Cancel style={{ fontSize: "30px" }} />
          </div>
        </DrawerHeader>
        <form>
          <DrawerBody>
            <div className="input-group">
              <label>Referrer ID</label>
              <div className="row1">
                <input type="text" />
                <button>Check</button>
              </div>
            </div>

            <div className="input-group">
              <label>Full name</label>
              <div className="row1">
                <input type="text" />
              </div>
            </div>

            <div className="input-group">
              <label>Commission for</label>
              <div className="row1">
                <select>
                  <option value="Kirah Court, 2 Whiterose Building">
                    Kirah Court, 2 Whiterose Building
                  </option>
                  <option value="Kirah Court, 2 Whiterose Building">
                    Kirah Court, 2 Whiterose Building
                  </option>
                  <option value="Kirah Court, 2 Whiterose Building">
                    Kirah Court, 2 Whiterose Building
                  </option>
                </select>
              </div>
            </div>

            <div className="input-group">
              <label>Amount</label>
              <div className="row1">
                <input type="text" />
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <div className="input-group">
              <label>Banker name</label>
              <div className="row1">
                <input type="text" />
              </div>
            </div>

            <div className="input-group">
              <div className="row4">
                <div className="col">
                  <label>Account name</label>
                  <input type="text" placeholder="Enter amount" />
                </div>
                <div className="col">
                  <label>Account name</label>
                  <input type="text" placeholder="Enter number of unit" />
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>Password</label>
              <div className="row1">
                <input type="password" placeholder="Enter account password" />
              </div>
            </div>

            <div className="submit">
              <button>Submit</button>
            </div>
          </DrawerFooter>
        </form>
      </Drawer>
    </>
  );
}

export default PayoutHeader;

PayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #0404061a;
`;

const DrawerBody = styled.div`
  padding: 0px 30px;
  border-bottom: 1px solid #0404061a;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }

  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }
`;

const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight:400;
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;
const OutflowHeaderStyles = styled.div`
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;


  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }

  .PopButtons {
    display: flex;
  }
`;

const JumbTabs = styled.div`
margin-top: 25px;

.tabs {
  margin-left: 20px;
  display: flex;
  
  a {
    padding: 10px 15px;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    color: #7D7D7E;
  }
  .active {
      border-bottom: 2px solid #FF385C;
      color: #000;
      background: transparent;
  }
`;
const NewReferralButtonStyles = styled.button`
font-size:15px;

  border-radius: 8px;
  background-color: #ff385c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  margin-right: 38px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;

  @media (max-width: 640px) {
    margin-top: 15px;
  }

  svg {
    margin-top: 9px;
    margin-left: 10px;
  }
  h5 {
    color: white;
  }
`;
