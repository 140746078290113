import styled from "@emotion/styled";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const data = [
  {
    name: "Jan",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Feb",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Mar",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Apr",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "May",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Jun",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Jul",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Aug",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Sep",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Oct",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Nov",
    Sales: 0,
    Membership: 0,
  },
  {
    name: "Dec",
    Sales: 0,
    Membership: 0,
  },
];
const DashboardChart = () => {
  return (
    <Div>
      <form action="">
        <ChartType name="chart_type" defaultValue="Inflow">
          <option value=""></option>
          <option value="Inflow">Inflow</option>
          <option value="Outflow">Outflow</option>
        </ChartType>
      </form>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
          height: "93%",
          position: "relative",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 30, right: 50, left: 50, bottom: 30 }}
          >
            <CartesianGrid horizontal={false} />
            <XAxis dataKey="name" fontSize={"12px"} />
            <YAxis />
            <Tooltip />
            <Legend align="right" fontSize={"12px"} iconType="square" />
            <Line type="linear" dataKey="Sales" stroke="#28C52B" dot={false} />
            <Line
              type="linear"
              dataKey="Membership"
              stroke="#8469FF"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Div>
  );
};

export default DashboardChart;

const Div = styled.div`
  // width: 100%;
  height: 45rem;
  background: #fff;
  padding: 20px;
`;

const ChartType = styled.select`
  padding: 10px;
  border-radius: 8px;
  font-size: 15px;
  padding-right: 7px;
`;
