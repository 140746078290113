import React, { useState } from "react";
import styled from "@emotion/styled";
import { FiSearch } from "react-icons/fi";
import MessagesContent from "components/layouts/MessagesContent";
import { messagesdata1 } from "helpers/datasets/messagesdata";
import Modal from "@mui/material/Modal";
import Close from "@mui/icons-material/Close";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Cancel } from "@mui/icons-material";

const Messages = () => {
  const [selected, setSelected] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [showMessageContent, setshowMessageContent] = useState(false);
  const matches = useMediaQuery("(max-width:1000px)");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  return (
    <>
      <TwoColumnGrid>
        <LeftColumn>
          <div className="header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <h1>Messages</h1>
                <p>0 sent messages </p>
              </div>

              <svg
                onClick={() => setModalShow(true)}
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="22.5" cy="22.5" r="22.5" fill="#FF385C" />
                <path
                  d="M21.1667 13.6667H19.5C15.3333 13.6667 13.6667 15.3333 13.6667 19.5V24.5C13.6667 28.6667 15.3333 30.3333 19.5 30.3333H24.5C28.6667 30.3333 30.3333 28.6667 30.3333 24.5V22.8333"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.8 21.0833C18.55 21.3333 18.3 21.825 18.25 22.1833L17.8917 24.6917C17.7583 25.6 18.4 26.2333 19.3083 26.1083L21.8167 25.75C22.1667 25.7 22.6583 25.45 22.9167 25.2L29.4833 18.6333C30.6167 17.5 31.15 16.1833 29.4833 14.5167C27.8167 12.85 26.5 13.3833 25.3667 14.5167L18.8 21.0833Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.425 15.4583C24.9834 17.4499 26.5417 19.0083 28.5417 19.5749"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <SearchForm className="inputDiv">
              <FiSearch />
              <input type="text" placeholder="Search message" />
            </SearchForm>
          </div>
          {messagesdata1.length > 0 ? (
            messagesdata1.map((message) => (
              <MessageCard
                key={message.id}
                onClick={() => {
                  setSelected(message);
                  setshowMessageContent(matches);
                }}
              >
                <div className="top">
                  <img src={message.authorImg} alt={message.authorName} />
                  <div className="title">
                    <div>
                      <h3>{message.authorName}</h3>
                      <h4>{message.title}</h4>
                    </div>
                    <p className="date">{message.dateAbr}</p>
                  </div>
                </div>
                <p>{message.content}</p>
              </MessageCard>
            ))
          ) : (
            <div className="nullDivM">
              <h1>No chat</h1>
            </div>
          )}
        </LeftColumn>
        <div className="messagesContent">
          {selected ? (
            <MessagesContent
              authorImg={selected?.authorImg}
              authorName={selected?.authorName}
              email={selected?.email}
              title={selected?.title}
              content={selected?.content}
              date={selected?.date}
              img={selected?.img}
            />
          ) : (
         ''
          )}
        </div>
        <div
          className="messagesContentMobile"
          style={{ display: showMessageContent === matches ? "block" : "none" }}
        >
          <div className="exitP">
            <Cancel
              style={{
                fontSize: "30px",
                color: "black",
              }}
              onClick={() => {
                setshowMessageContent(false);
              }}
            />
          </div>
          {selected && (
            <MessagesContent
              authorImg={selected?.authorImg}
              authorName={selected?.authorName}
              email={selected?.email}
              title={selected?.title}
              content={selected?.content}
              date={selected?.date}
              img={selected?.img}
            />
          )}
        </div>
        <Modal
          open={modalShow}
          onClose={() => setModalShow(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalBody>
            <ModalHeader>
              <div className="header">
                <p>New message</p>
                <Close onClick={() => setModalShow(false)} />
              </div>
              <form>
                <input type="text" placeholder="To:" />
                <br />
                <input type="text" placeholder="Subject:" />
                <br />
              </form>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </ModalHeader>

            <ModalFooter>
              <button className="button" onClick={() => setModalShow(false)}>
                Send
              </button>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </TwoColumnGrid>
    </>
  );
};

export default Messages;

const TwoColumnGrid = styled.div`
  // display: grid;
  // grid-template-columns: 400px 1fr;
  // height: 100%;
  // min-height: 100vh;
  // background: #fff;
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .messagesContent {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .messagesContentMobile {
    @media (max-width: 1000px) {
      top: 0px;
      width: 100%;
      position: fixed;
      background: white;
      height: auto;
      min-height: 140vh;
    }
  }
`;

const LeftColumn = styled.div`
  border-right: 1px solid #e5e5e5;
  flex-basis: 35%;

  // @media (max-width: 1000px) {
  //   display: none;
  // }

  .header {
    padding: 40px;
    border-bottom: 1px solid #e5e5e5;

    h1 {
      font-size: 24px;
    }
    p {
      color: #7d7d7e;
      font-size: 13px;
    }
  }
`;

const SearchForm = styled.div`
  display: flex;
  border: 1px solid rgba(4, 4, 6, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  margin: 20px;
  margin-left: 0px;
  margin-bottom: 0px;

  svg {
    font-size: 14.5px;
    stroke: #7d7d7e;
  }
  input {
    margin-left: 10px;
    background: transparent;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: #7d7d7e;
  }
`;

const MessageCard = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;

  .top {
    display: flex;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 10px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .date {
        font-size: 12px;
        color: #7d7d7e;
      }
    }
  }

  p {
    color: #7d7d7e;
    font-size: 13px;
  }
`;
const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 645px;
  height: auto;
  min-height: 420px;
  background: #fff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const ModalHeader = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid whitesmoke;
  }
  form {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  input {
    padding: 15px 0px;
    border: none;
    border-bottom: 1px solid whitesmoke;
    width: inherit;
    outline: none;
  }

  .wrapper-class {
    padding: 14px;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color: lightgray;
    padding: 14px;
    border: 1px solid #ccc;
    height: 10vh;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0px 15px;
  margin-top: 40px;
  }

  div {
    color: #7d7d7e;
  }

  button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #FF385C;
    color: white;
  }
`;
