import React from "react";

import styled from "@emotion/styled";

import PropTypes from "prop-types";


function RegularHeader({ title, subTitle }) {
  return (
    <>
    <OutflowHeaderStyles>
      <div className="meta">
        <HeaderTab>
          <div style={{ display: "flex" }}>
            <h3>{title}</h3>
          </div>
        </HeaderTab>
        <p style={{ marginTop: '15px', fontSize: '15px', color: "#7D7D7E" }}>{subTitle}</p>
      </div>

    </OutflowHeaderStyles>
    </>
  );
}

export default RegularHeader;

RegularHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const OutflowHeaderStyles = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  .PopButtons {
    display: flex;
  }
`;

