import Dashboard from "components/pages/Dashboard";
import Membership from "components/pages/Membership";
import Inflow from "components/pages/Inflow";
import { Routes, Route } from "react-router-dom";
import Outflow from "components/pages/Outflow";
import Salaries from "components/pages/Salaries";
import Clients from "components/pages/Clients";
import Payout from "components/pages/Payout";
import Members from "components/pages/Members";
import ReferralRanking from "components/pages/ReferralRanking";
import SalesRanking from "components/pages/SalesRanking";
import ClientRanking from "components/pages/ClientRanking";
import Properties from "components/pages/Properties";
import DiscountedSalaries from "components/pages/DiscountedSalaries";
import Settings from "components/pages/Settings";
import Login from "components/pages/Login";
import Messages from "components/pages/Messages";

const PagesRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/inflow" element={<Inflow />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/outflow" element={<Outflow />} />
      <Route path="/salaries" element={<Salaries />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/payout" element={<Payout />} />
      <Route path="/discounted-salaries" element={<DiscountedSalaries />} />
      <Route path="/members" element={<Members />} />
      <Route path="/properties" element={<Properties />} />
      <Route path="/referral-ranking" element={<ReferralRanking />} />
      <Route path="/sales-ranking" element={<SalesRanking />} />
      <Route path="/client-ranking" element={<ClientRanking />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/messages" element={<Messages />} />
    </Routes>
  );
};

export default PagesRoutes;
