import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const EmptyTable = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100%",
        height: 400,
        background: "#fff",
        borderRadius: 5,
      }}
    >
      <Grid item>
        <Typography variant="h4" gutterBottom color="primary">
          No data yet
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ fontSize: "14px" }}>
          We have no computed any data for this table yet
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyTable;
