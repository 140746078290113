import axios from "axios";
import { BASE_URL } from "./BASE_URL";

const token = localStorage.getItem("token");

export const AddProperty = async (propertyData) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/admin/properties`,
      propertyData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const GetProperties = async (searchTerm, filter) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/properties/all${searchTerm || filter ? "?" : ""}${
        searchTerm ? "search_term=" + searchTerm + "&" : ""
      }${filter ? "filter=" : ""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const GetAdminStats = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/dashboard/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const GetSingleProperty = async (propertyID) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/properties/${propertyID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const EditSingleProperty = async (propertyID, propertyData) => {
  try {
    const { data } = await axios.patch(
      `${BASE_URL}/api/admin/properties/${propertyID}`,
      propertyData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteSingleProperty = async (propertyID) => {
  try {
    const { data } = await axios.delete(
      `${BASE_URL}/api/admin/properties/${propertyID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

// client

export const createClient = async (details) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/admin/clients`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};
export const getAllClients = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/clients/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllClientsS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/clients/all?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getAClients = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/clients/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const patchAClient = async (id, datas) => {
  try {
    const { data } = await axios.patch(
      `${BASE_URL}/api/admin/clients/${id}`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteAClient = async (id) => {
  try {
    const { data } = await axios.delete(
      `${BASE_URL}/api/admin/clients/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getUserByReferralCode = async (property, value) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/${property}/refcode/?refcode=${value}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllClientsCommission = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/commissions/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllClientsCommissionS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/commissions/all?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getACommission = async (id) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/commissions/${id}?`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};
export const patchACommission = async (id, datas) => {
  try {
    const { data } = await axios.patch(
      `${BASE_URL}/api/admin/commissions/${id}?`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};
// export const getACommission = async (id) => {
//   try {
//     const { data } = await axios.get(
//       `${BASE_URL}/api/admin/commissions/${id}?`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     return data.data;
//   } catch (error) {
//     console.error(error.message);
//   }
// };

export const getAllMembers = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/users/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllMembersS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/users/all?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllMembersByID = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
// console.log(data.data.email,'from func')
    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllClientsRanking = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/clients/ranking`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllClientsRankingS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/clients/ranking?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllSalesRanking = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/sales/ranking`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getAllSalesRankingS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/sales/ranking?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteAComission = async (id) => {
  try {
    const { data } = await axios.delete(
      `${BASE_URL}/api/admin/commissions/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};
export const getProperties = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/properties/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getPropertiesS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/properties/all?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const addProperties = async (dataValue) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/admin/sales`,
      dataValue,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllSalesPropertiesS = async (searchQuery) => {
  console.log(searchQuery, "from api search");
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/sales/all?service=mortgage&search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllSalesProperties = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/sales/all?service=mortgage`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllMembershipProperties = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/sales/all?service=registration`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAllMembershipPropertiesS = async (searchQuery) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/admin/sales/all?service=registration?search_term=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getASale = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/api/admin/sales/${id}?`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
export const editACommission = async (id, details) => {
  try {
    const { data } = await axios.patch(
      `${BASE_URL}/api/admin/sales/${id}?`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const setUserActiveOrInActive = async (id, status) => {
  try {
    const { data } = await axios.patch(
      `${BASE_URL}/api/admin/users/managestatus`,
      { status: status, userId: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

//setUserActiveOrInActive

export const deleteASale = async (id) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/api/admin/sales/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
