import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@mui/styles";
import { BsArrowRight } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  menu: {
    maxHeight: "35rem !important",

    "& .MuiMenu-list": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },

    "&::-webkit-scrollbar": {
      width: ".85rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 14px rgba(0, 0, 0, 0.2)",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: ".5rem",
      background: theme.palette.common.lightGrey,

      "&:active": {
        background: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      background: "transparent !important",

      "&:hover": {
        //   background: "transparent",
        color: theme.palette.secondary.main,

        "& .menu-icon": {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
}));


const HeaderProfileOptions = ({ openOptions, setOpenOptions, handleLogout }) => {
  const menus = [
    { id: 0, title: "Change Password", icon: BsArrowRight },
    { id: 1, title: "Logout", icon: BiLogOut, func: handleLogout },
  ];

  const handleClose = () => {
    setOpenOptions(null);
  };
  const classes = useStyles();
  //   const theme = useTheme();

  const open = Boolean(openOptions);

  return (
    <Menu
      open={open}
      anchorEl={openOptions}
      onClose={handleClose}
      id="dropdown-arrow-menu"
      MenuListProps={{
        "aria-labelledby": "dropdown-arrow",
      }}
      className={classes.menu}
    >
      {menus.map((menu) => (
        <MenuItem
          key={menu.id}
          onClick={menu.func}
          disableRipple
          className={classes.menuItem}
          style={{ paddingTop: "14px", paddingBottom: "14px", fontSize: "15px" }}
        >
          <ListItemIcon>
            {React.createElement(menu.icon, {
              size: "2rem",
              className: "menu-icon",
            })}
          </ListItemIcon>
          {menu.title}
        </MenuItem>
      ))}
    </Menu>
  );
};

HeaderProfileOptions.propTypes = {
  openOptions: PropTypes.object,
  setOpenOptions: PropTypes.func.isRequired,
};

export default HeaderProfileOptions;
