import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { Carousel } from "react-carousel-minimal";

import {
  Box,
  Button,
  Grid,
  Typography,
  Drawer,
  CircularProgress,
} from "@mui/material";
import DashboardOverviewCard from "components/utils/DashboardOverviewCard";
import { makeStyles } from "@mui/styles";
import DashboardChart from "components/layouts/DashboardChart";
import DashboardBuildingCard from "components/layouts/DashboardBuildingCard";
import FormSelect from "components/utils/FormSelect";
import styled from "@emotion/styled";
import prettyMoney from "pretty-money";
import { IconExport1 } from "../../assets/icons";
import { saveAs } from "file-saver";
import {
  IconCommission,
  IconDownlines,
  IconMembership,
  IconSales,
} from "assets/icons";
import { Link } from "react-router-dom";
import { GetProperties, GetAdminStats } from "services/properties";
import EmptyProperty from "components/layouts/EmptyProperty";

let PropertyDoc = "https://drive.google.com/file/d/1vuIBVSuKorxe287yA7t-VNqPP-DuHJRn/view?usp=share_link"


const useStyles = makeStyles((theme) => ({
  overviewCardWrapper: {
    // display: "grid",
    // maxWidth: "100%",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr",
    // gridGap: "2rem",
    marginTop: "5rem",
    marginBottom: "5rem",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",
  },
  buildingCardWrapper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  button: {
    "&.MuiButton-root": {
      background: "#fff",
      padding: "1.5rem 2rem",
      borderRadius: "5rem",
      color: theme.palette.common.dark,
      textTransform: "none",
      fontSize: "1.6rem",
    },
    "&:hover": {
      color: "#111",
    },
  },
}));
const Dashboard = () => {
  const [showBuilding, setShowBuilding] = useState(false);
  const [prevBuilding, setPrevBuilding] = useState({});
  const [properties, setProperties] = useState(false);
  const [stats, setStats] = useState(false);

  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    GetProperties()
      .then((data) => {
        if (data.message === "successful") {
          setProperties(data.data.properties.properties);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
    GetAdminStats()
      .then((data) => {
        if (data.message === "successful") {
          setStats(data.data.result);
          setPending(false);
          console.log(data.data.result, "stats");
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
  }, []);
  let img = [];
  if (prevBuilding.images) {
    prevBuilding.images.map((i) => {
      console.log(i.url);
      img.push({ image: i.url, caption: "" });
    });
  }

  console.log("xxx", img, prevBuilding.images);

  const theme = useTheme();

  const datasets = [
    {
      id: 0,
      icon: <IconSales />,
      color: theme.palette.info.main,
      title: "Total inflow",
      figure: stats ? stats.total_inflow : 0,
    },
    {
      id: 1,
      icon: <IconCommission />,
      color: theme.palette.success.main,
      title: "Total outflow",
      figure: 0,
    },
    {
      id: 2,
      icon: <IconMembership />,
      color: theme.palette.warning.main,
      title: "Total clients",
      figure: stats ? stats.total_clients_count : 0,
    },
    {
      id: 3,
      icon: <IconDownlines />,
      color: theme.palette.common.deepBlue,
      title: "Total members",
      figure: stats ? stats.total_users_count : 0,
    },
  ];

  const classes = useStyles();
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  const downloadImage = () => {
    let img = prevBuilding?.banner;
    saveAs(img, "image.jpg"); // Put your image url here.
  };
  const downloadImage1 = () => {
    let img = prevBuilding?.propertyDoc;
    saveAs(img, "document.pdf"); // Put your image url here.
  };
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  let show = prevBuilding?.banner == "null" ? false : true;
  console.log(process.env.REACT_APP_BASE_URI, "eeenv");

  return (
    <>
      <Grid style={{ padding: "30px 3rem 14px" }}>
        <Grid item>
          <Typography variant="h1" gutterBottom>
            Welcome back, Admin
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.grey }}
          >
            Here is what we have for you.
          </Typography>
        </Grid>
        <div className={classes.overviewCardWrapper}>
          {datasets.map((data) => (
            <DashboardOverviewCard key={data.id} data={data} />
          ))}
        </div>
        <Grid>
          <DashboardChart />
        </Grid>
        <Grid container direction="column" mt={5}>
          <Grid container justifyContent="space-between" className="flexCol">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: "600" }}>
                Available Properties ({properties.length || 0})
              </Typography>
            </Grid>
            <Grid item className="m20">
              <Grid container alignItems="center">
                <Grid item mr={1}>
                  <Typography variant="body1">Sort by</Typography>
                </Grid>
                <Grid item>
                  <FormSelect
                    options={[1, 2, 3, 4, 5]}
                    style={{ height: "3.5rem", width: "15rem" }}
                    placeholderText="All"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.buildingCardWrapper}>
            {pending ? (
              <Box
                sx={{
                  display: "flex",
                  height: "60vh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : properties.length > 0 ? (
              properties
                .slice(0, 6)
                .map((building) => (
                  <DashboardBuildingCard
                    key={building.id}
                    building={building}
                    setShowBuilding={setShowBuilding}
                    setPrevBuilding={setPrevBuilding}
                  />
                ))
            ) : (
              <EmptyProperty />
            )}
          </div>

          {properties.length > 2 && (
            <Grid container justifyContent="center" mt={5}>
              <Link to="/properties" style={{ textDecoration: "none" }}>
                <Button variant="contained" className={classes.button}>
                  View all properties
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Drawer
        className="drawerV"
        anchor="right"
        open={showBuilding}
        sx={{ width: 300 }}
        onClose={() => setShowBuilding(false)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>

          <div className="exit" onClick={() => setShowBuilding(false)}>
            <Cancel style={{ fontSize: "30px" }} />
          </div>
        </div>
        <DrawerHeader>
          <p>{prevBuilding?.category}</p>
          <h1>{prevBuilding?.name}</h1>
          <p style={{ color: "#7D7D7E" }}>{prevBuilding?.address}</p>

          {prevBuilding?.images && (
            // <img
            //   src={prevBuilding?.images[0]?.url || prevBuilding?.images[1]?.url}
            //   alt="Beach Home Villa"
            // />
            <Carousel
              data={img}
              time={2000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              // slideNumber={true}
              // slideNumberStyle={slideNumberStyle}
              // automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="transparent"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "auto",
                margin: "40px auto",
              }}
            />
          )}

          <p className="desc">{prevBuilding?.description}</p>

          <div className="table tableU">
            {/* <div className="row">
              <span className="title">Units Available</span>
              <span className="final">
                {prevBuilding?.units_available}
              </span>
            </div> */}
            <div className="row">
              <span className="title">City</span>
              <span className="final">{prevBuilding?.city}</span>
            </div>
            <div className="row">
              <span className="title">State</span>
              <span className="final">{prevBuilding?.state}</span>
            </div>
            {/* <div className="row">
              <span className="title">Commission</span>
              <span className="final">
                {" "}
                {prevBuilding?.commission}%
              </span>
            </div> */}
             {prevBuilding?.name == "Lekki Town Square Mall" ?(
              <>
              <div className="row">
              <span className="title">                      Ground Floor
</span>
              <span className="final">
              ₦1,350,000 per sqm 
              </span>
            </div>
            <div className="row">
              <span className="title">                      First Floor
</span>
              <span className="final">
              ₦1,200,000 per sqm
              </span>
            </div>
            <div className="row">
              <span className="title">                      2nd floor
</span>
              <span className="final">
              ₦1,000,000
              </span>
            </div>
            <div className="rowN">
              <span className="title">                      Store size
</span>
              <span className="final">
              38 sqm and above
              </span>
            </div>
              </>
            ):(<div className="rowN">
              <span className="title">Price</span>
              <span className="final">
                {prettyMoney(prettyDollarConfig, prevBuilding?.price)}
              </span>
            </div>)}
            {/* <div className="rowN">
              <span className="title">Commission</span>
              <span className="final" >
                {prevBuilding?.commission}%
              </span>
            </div> */}
          </div>
        </DrawerHeader>
        {show && (
          <DrawerFooter>
          {/* <h1>Property Banner</h1>
          <img src={prevBuilding?.banner} alt="Banner" /> */}
        <div className="submit" style={{    display: 'flex'}}>
            <button onClick={downloadImage}>
              <IconExport1 /> Download image
            </button>
            {prevBuilding?.name == "Lekki Town Square Mall" ?(<button style={{background:'black',marginLeft: '5%'}} onClick={downloadImage1}>
              <IconExport1 /> Download PDF
            </button>) :(<button style={{background:'black',marginLeft: '5%'}} >
              <IconExport1 /> Download PDF
            </button>)}

            
          </div>
        </DrawerFooter>
        )}
      </Drawer>
    </>
  );
};

export default Dashboard;

// const Main = styled.div`
//   .grid {
//     @media (max-width: 1000px) {
//       padding: 12rem 2rem 3rem 2rem !important;
//     }
//   }

//   .overviewCardWrapper {
//     @media (max-width: 1000px) {
//       justify-content: center;
//     }
//   }
// `;

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #0404061a;

  h1 {
    margin: 10px 0px;
  }

  img {
    margin: 20px 0px;
    width: 400px;
  }

  .desc {
    width: 445px;
    line-height: 25px;
  }

  .table {
    margin-top: 10px;

    .row {
      padding: 15px 0px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;

      .title {
        color: #7d7d7e;
      }
      .final {
        font-size: 15px;
      }
    }
    .rowN {
      padding: 15px 0px;
      display: flex;
      justify-content: space-between;

      .title {
        color: #7d7d7e;
      }
      .final {
        font-size: 15px;
      }
    }
  }
`;

const DrawerFooter = styled.div`
  padding: 30px;

  img {
    margin: 20px 0px;
    width: 400px;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 155px;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        stroke: #fff;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
`;
