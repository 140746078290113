import { useState, useEffect, useCallback } from "react";
import EnhancedTable from "../utils/table-layouts/EnhancedTable";
import { SaleRankingHeadCells } from "../../helpers/datasets/table-header-data";
import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dateMoment } from "../utils/Time";
import DeleteModals from "components/utils/DeleteModals";
import { deleteAComission, getAllSalesRanking } from "services/properties";
import prettyMoney from "pretty-money";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const SaleRankingTable = ({
  state,

  setState,
}) => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    setValues(state);
  }, [state]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [id] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);

  const handleDelete = useCallback(async () => {
    await deleteAComission(id);
    const data = await getAllSalesRanking();
    handleCloseDelete();
    setState(data.users);
  }, [id, setState]);

  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  return (
    <>
      <EnhancedTable
        headCells={SaleRankingHeadCells}
        rows={values}
        page={page}
        paginationLabel="Showing"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {values
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => {
            const {
              created_on,
              id,
              client_referr_id,
              total_sales_amount,
              total_units_sold,
              full_name,
              phone,
            } = row;
            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell className={classes.tableCell}>
                  <input type="checkbox" />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {dateMoment(created_on)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {`${full_name}`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {phone ? phone : "null"}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  
                >
                  {client_referr_id ? client_referr_id : "null"}
                </TableCell>

                <TableCell
                  className={classes.tableCell}
                  
                >
                  {" "}
                  {total_units_sold}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prettyMoney(prettyDollarConfig, total_sales_amount)}
                </TableCell>
              </TableRow>
            );
          })}
      </EnhancedTable>
      <DeleteModals
        handleDelete={handleDelete}
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
      />
    </>
  );
};

export default SaleRankingTable;

// const StatusBtn = styled.button`
//   padding: 7px 10px;
//   border-radius: 6px;
//   border: none;
//   outline: none;
//   font-size: 13px;

//   &.ACTIVE {
//     background: #1ec6771a;
//     color: #1ec677;
//   }
//   &.INACTIVE {
//     background: #ed32371a;
//     color: #ed3237;
//   }
// `;
// const Popover = styled.div`
//   position: absolute;
//   top: auto;
//   right: 90px;
//   background: #fff;
//   border-radius: 7px;
//   z-index: 1000;
//   box-shadow: 2px 2px 2px #cccccc1a;
// `;
