import React, { useCallback } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Drawer } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { patchACommission, getAllClientsCommission } from "services/properties";
//

const OutflowHeader = ({
  title,
  addPay,
  setAddPay,
  subTitle,
  setState,
  state,
  set,
}) => {
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setState({
        ...state,
        [name]: value,
      });
    },
    [state, setState]
  );

  const handleSubmit = async (e) => {
    const {
      id,
      clientFullName,
      commission,
      referrerId,
      propertyName,
      clientId,
    } = state;
    e.preventDefault();
    await patchACommission(id, {
      clientFullName,
      commission,
      propertyName,
    });
    const value = await getAllClientsCommission();
    set(value.commissions.commissions);
  };

  const { clientFullName, commission, referrerId, propertyName, clientId } = state;

  return (
    <>
      <OutflowHeaderStyles>
        <div className="meta">
          <HeaderTab>
            <div style={{ display: "flex" }}>
              <h3>{title}</h3>
              <div className="tabs">
                {document.location.pathname === "/outflow" ? (
                  <>
                    <Link to="/outflow" className="active">
                      Commissions
                    </Link>
                    <Link to="/salaries">Salaries</Link>
                  </>
                ) : (
                  <>
                    <Link to="/outflow">Commissions</Link>
                    <Link to="/salaries" className="active">
                      Salaries
                    </Link>
                  </>
                )}
              </div>
            </div>
          </HeaderTab>
          <p style={{ marginTop: "15px", fontSize: "15px", color: "#7D7D7E" }}>
            <span
              style={{ fontSize: "2rem", fontWeight: "bold", color: "#000" }}
            >
              {subTitle}
            </span>{" "}
            {document.location.pathname === "/outflow"
              ? "paid commissions"
              : "paid salaries"}
          </p>
        </div>

        <DrawerDiv>
          <Drawer
            anchor="right"
            open={addPay}
            sx={{ minWidth: "450px" }}
            onClose={() => setAddPay(false)}
            className="drawer"
          >
            <DrawerHeader>
              <div>
                <h1>Comission</h1>
                <p className="subText">List of commissions due for payment</p>
              </div>
              <div className="exit" onClick={() => setAddPay(false)}>
                <Cancel style={{ fontSize: "30px" }} />
              </div>
            </DrawerHeader>
            <form onSubmit={handleSubmit}>
              <DrawerFooter>
                <div className="input-group">
                  <label>Client ID</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="clientId"
                      onChange={handleChange}
                      value={clientId}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="row4">
                    <div className="col">
                      <label>Full name</label>
                      <input
                        disabled
                        type="text"
                        name="fullname"
                        value={clientFullName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <label>Commission</label>
                      <input
                        type="text"
                        name="commission"
                        value={commission}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label>Referrer Id</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="referrerId"
                      onChange={handleChange}
                      value={referrerId}
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label>Property Name</label>
                  <div className="row1">
                    <input
                      type="text"
                      name="propertyName"
                      value={propertyName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="submit">
                  <button>Submit</button>
                </div>
              </DrawerFooter>
            </form>
          </Drawer>
        </DrawerDiv>
      </OutflowHeaderStyles>
    </>
  );
};

export default OutflowHeader;

OutflowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const OutflowHeaderStyles = styled.div`
  padding-bottom: 25px;
  padding-top: 25px;

  .meta {
    display: flex;
    flex-direction: column;
    padding-left: 38px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;
const HeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  .tabs {
    margin-left: 20px;
    background: #eaeaea;
    display: flex;
    padding: 5px;
    border-radius: 6px;

    a {
      padding: 8px 15px;
      border-radius: 6px;
      text-decoration: none;
      color: #000;
      font-size: 12px;
    }
    .active {
      background: #fff;
    }
  }

  .PopButtons {
    display: flex;
  }
`;
const DrawerDiv = styled.div`
  .drawer {
    @media (max-width: 500px) {
      width: 100% !important;
    }
  }
`;
const DrawerFooter = styled.div`
  padding: 0px 30px;
  padding-bottom: 30px;

  .input-group {
    margin: 20px 0px;
    label {
      font-size: 13px;
    }
  }
  .row4 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px 10px;

    .col {
      display: flex;
      flex-direction: column;

      input {
        padding: 10px;
        border-radius: 8px;
        outline: none;
        border: 1px solid #ccc;
        width: 105px;
        margin-top: 5px;
        width: 155px;
      }
    }
  }

  .row1 {
    display: flex;
    margin: 5px 0px;

    button {
      padding: 10px;
      margin-left: 10px;
      border-radius: 8px;
      background: #ff385c;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
    }
  }

  input {
    padding: 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  select {
    padding: 8px 10px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #ccc;
    flex: 1;
  }

  .submit {
    button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      padding: 10px 0px;
      background: #ff385c;
      color: #fff;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`;

const DrawerHeader = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0404061a;
`;
