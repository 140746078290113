import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { makeStyles, useTheme } from "@mui/styles";
import MiniSearchBar from "components/layouts/MiniSearchBar";

import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "14px",
      paddingBottom: "14px",
      borderColor: "red",
      width: "200px",

      "&::placeholder": {
        color: theme.palette.common.grey,
        opacity: 1,
      },

      "&:hover": {
        borderColor: "red",
      },
    },
  },
}));

const FormInput = ({
  width,
  placeholder,
  value,
  onChange,
  hasStartIcon,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Div className={classes.root}>
      {/* <OutlinedInput
        id="input-with-icon-adornment"
        value={value}
        onChange={onChange}
        {...rest}
        sx={{
          display: "flex",
          alignItems: "center",
          width,
          fontSize: "1.6rem",
          background: "white",
          borderRadius: 2,
          fontWeight: 400,
          marginLeft: "-5%",
        }}
        className={`input ${classes.input}`}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        startAdornment={
          hasStartIcon && (
            <InputAdornment position="start">
              <FiSearch
                size="2rem"
                style={{ color: theme.palette.common.grey }}
              />
            </InputAdornment>
          )
        }
      /> */}

      <div className="inputSearch">
        <MiniSearchBar
          color="#fff"
          onChange={"handleChange"}
          placeHolder="Search anything..."
          className="longSearchHeader"
        />
      </div>
    </Div>
  );
};
FormInput.propTypes = {
  width: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  hasStartIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FormInput.defaultProps = {
  hasStartIcon: true,
};

export default FormInput;

const Div = styled.div`
  .input {
    @media (max-width: 622px) {
      width: 250px !important;
    }
    @media (max-width: 460px) {
      width: 180px !important;
      margin-left: 3px;

    }
  }
`;
