export const headers = [
  { key: "ammountJustPaid", label: "ammountJustPaid" },
  { key: "clientId", label: "clientId" },
  { key: "costPerUnit ", label: "costPerUnit" },
  { key: "createdAt", label: "createdAt" },
  { key: "dateOfPayment", label: "dateOfPayment" },
  { key: "deletedAt", label: "deletedAt" },
  { key: "fullName", label: "fullName" },
  { key: "metadata", label: "metadata" },
  { key: "mobile", label: "mobile" },
  { key: "numberOfunits", label: "numberOfunits" },
  { key: "payableTypeId", label: "payableTypeId" },
  { key: "paymentPlan", label: "paymentPlan" },
  { key: "propertyName", label: "propertyName" },
  { key: "propertyPrice", label: "propertyPrice" },
  { key: "reference", label: "reference" },
  { key: "status", label: "status" },
  { key: "totalAmmount", label: "totalAmmount" },
  { key: "updatedAt", label: "updatedAt" },
  { key: "userId", label: "userId" },
];
export const outFlowHeaders = [
  { key: "commission", label: "commission" },
  { key: "clientId", label: "clientId" },
  { key: "clientFullName", label: "clientFullName" },
  { key: "adminId", label: "adminId" },
  { key: "updatedAt", label: "updatedAt" },
  { key: "status", label: "status" },
  { key: "referrerId", label: "referrerId" },
  { key: "propertyName", label: "propertyName" },
  { key: "key", label: "key" },
  { key: "deletedAt", label: "deletedAt" },
];

export const memberHeaders = [
  {
    key: "firstName",
    label: "firstName",
  },
  {
    key: "email",
    label: "email",
  },
  {
    key: "level",
    label: "level",
  },
  {
    key: "referredUsers",
    label: "referredUsers",
  },
  {
    key: "myReferralCode",
    label: "myReferralCode",
  },
  {
    key: "status",
    label: "status",
  },
  {
    key: "Action",
    label: "Action",
  },
];
