import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { abbreviateNumber } from "helpers/abbreviateNumber";
import { makeStyles, useTheme } from "@mui/styles";
import styled from "@emotion/styled";
import prettyMoney from "pretty-money";

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.common.lighterGrey}`,
    width: "100%",
    padding: "2rem",
    borderRadius: 10,
    background: "#fff",
  },
}));

const DashboardOverviewCard = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  return (
    <Main className="cardPer">
      <Grid item className={`card ${classes.card}`}>
        <Grid container direction="column">
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: "2rem" }}
          >
            <Grid item>
              <Typography
                variant="body1"
                style={{ color: theme.palette.common.grey }}
              >
                {data.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ color: data.color }}>
                {data.icon}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h2" fontSize={"25px"}>
              <span
                style={{
                  textDecorationLine: "line-through",
                  textDecorationStyle: "double",
                }}
              >
              </span>
              {data.id === 0 || data.id === 1
                ? prettyMoney(prettyDollarConfig, data.figure)
                : data.figure}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Main>
  );
};

export default DashboardOverviewCard;

const Main = styled.div`
  .card {
    @media (max-width: 1000px) {
      width: 100%;
      margin-top: 15px;
    }
  }
`;
